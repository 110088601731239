// MainLayout.tsx
import React, { useEffect } from 'react';
import LeftNavBar from './LeftNavBar';
import GladiatorBar from './GladiatorBar';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import './MainLayout.css';
import { useGladiator } from '../../contexts/GladiatorContext';
import { useAuth } from '../../contexts/AuthContext';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { gladiator, setGladiatorId } = useGladiator();
  const { user, setUser } = useAuth();

  useEffect(() => {
    if (gladiator) {
      setGladiatorId(gladiator.id);
    } else {
      setGladiatorId(null);
    }
  }, [gladiator, setGladiatorId]);

  return (
    <div className="layout-container">
      <Header user={user} />
      <div className="main-content">
        {user && <LeftNavBar />}
        <div className="content-area">
          {children}
        </div>
        {user && gladiator && (
          <GladiatorBar />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default MainLayout;
