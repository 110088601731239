import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCircle } from '@fortawesome/free-solid-svg-icons';
import SendMail from './SendMail';
import Toast from '../Toast';
import { useSocket } from '../../../contexts/SocketContext';
import styles from './FriendsList.module.css';

interface Friend {
  id: number;
  name: string;
  level: number;
  reputation: number;
  avatar: number;
  race: string;
  sex: string;
  isOnline: boolean;
  lastSeen?: string;
  gladiator: {
    id: number;
    userId: number;
    name: string;
    reputation: number;
    race: string;
    sex: string;
    avatar: number;
    level: number;
  };
  type: 'incoming' | 'outgoing';
}

const FriendsList: React.FC = () => {
  const [friends, setFriends] = useState<Friend[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pendingFriends, setPendingFriends] = useState<Friend[]>([]);
  const [showSendMail, setShowSendMail] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState<Friend | null>(null);
  const { socket } = useSocket();
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const friendsPerPage = 20;

  useEffect(() => {
    fetchFriends(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchPendingFriends();
  }, []);

  const fetchPendingFriends = async () => {
    try {
      const response = await axios.get(`/api/friend/pending`, { withCredentials: true });
      setPendingFriends(response.data.pendingFriends);
    } catch (error) {
      displayToast('Error fetching pending friend requests', 'error');
    }
  };

  const fetchFriends = async (page: number) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/friend/list`, {
        params: { page, limit: friendsPerPage },
        withCredentials: true,
      });
      setFriends(response.data.friends);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      displayToast('Error fetching friends', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptFriendRequest = async (friendId: number) => {
    try {
      await axios.post('/api/friend/accept', { friendId }, { withCredentials: true });
      setPendingFriends((prev) => prev.filter((friend) => friend.id !== friendId));
      setFriends((prev) => [...prev, pendingFriends.find((friend) => friend.id === friendId) as Friend]);
      displayToast('Friend request accepted', 'success');
      
      if (socket) { socket.emit('friendRequestHandled'); }
    } catch (error) {
      displayToast('Error accepting friend request', 'error');
    }
  };
  
  const handleDeclineFriendRequest = async (friendId: number) => {
    try {
      await axios.post('/api/friend/decline', { friendId }, { withCredentials: true });
      setPendingFriends((prev) => prev.filter((friend) => friend.id !== friendId));
      displayToast('Friend request declined', 'success');
      if (socket) {  socket.emit('friendRequestHandled'); }
    } catch (error) {
      displayToast('Error declining friend request', 'error');
    }
  };

  const handleCancelFriendRequest = async (friendId: number) => {
    try {
      await axios.post('/api/friend/cancel', { friendId }, { withCredentials: true });
      setPendingFriends((prev) => prev.filter((friend) => friend.id !== friendId));
      displayToast('Friend request canceled', 'success');
    } catch (error) {
      displayToast('Error canceling friend request', 'error');
    }
  };

  const handleRemoveFriend = async (friendId: number) => {
    try {
      await axios.post('/api/friend/remove', { friendId }, { withCredentials: true });
      setFriends((prev) => prev.filter((friend) => friend.id !== friendId));
      if (friends.length === 1 && currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      }
      displayToast('Friend removed', 'success');
    } catch (error) {
      displayToast('Error removing friend', 'error');
    }
  };

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const goToPage = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const openSendMailModal = (friend: Friend) => {
    setSelectedFriend(friend);
    setShowSendMail(true);
  };

  const closeSendMailModal = () => {
    setShowSendMail(false);
    setSelectedFriend(null);
  };

  if (loading) return <p>Loading friends...</p>;

  const incomingRequests = pendingFriends.filter(friend => friend.type === 'incoming');
  const outgoingRequests = pendingFriends.filter(friend => friend.type === 'outgoing');

  return (
    <div className={styles.friendsContainer}>
      <h1 className={styles.title}>Friends List</h1>

      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      {pendingFriends.length > 0 && (
        <div className={styles.pendingSection}>
          <h2>Pending Requests</h2>
          <table className={styles.friendsTable}>
            <thead>
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th>Level</th>
                <th>Reputation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {incomingRequests.map((friend) => (
                <tr key={friend.id}>
                  <td>
                    <img
                      src={`/img/avatars/${friend.gladiator.race?.toLowerCase() ?? 'default'}.${friend.gladiator.sex?.toLowerCase() ?? 'default'}.${friend.gladiator.avatar ?? 1}.png`}
                      alt={friend.name}
                      className={styles.avatar}
                    />
                  </td>
                  <td>
                    <Link to={`/gladiator/${friend.gladiator.id}`} className={styles.profileLink}>
                      {friend.name}
                    </Link>
                  </td>
                  <td>{friend.gladiator.level}</td>
                  <td>{friend.gladiator.reputation}</td>
                  <td>
                    <button onClick={() => handleAcceptFriendRequest(friend.id)} className={styles.acceptButton}>
                      Accept
                    </button>
                    <button onClick={() => handleDeclineFriendRequest(friend.id)} className={styles.declineButton}>
                      Decline
                    </button>
                  </td>
                </tr>
              ))}
              {outgoingRequests.map((friend) => (
                <tr key={friend.id}>
                  <td>
                    <img
                      src={`/img/avatars/${friend.gladiator.race?.toLowerCase() ?? 'default'}.${friend.gladiator.sex?.toLowerCase() ?? 'default'}.${friend.gladiator.avatar ?? 1}.png`}
                      alt={friend.name}
                      className={styles.avatar}
                    />
                  </td>
                  <td>
                    <Link to={`/gladiator/${friend.gladiator.id}`} className={styles.profileLink}>
                      {friend.name}
                    </Link>
                  </td>
                  <td>{friend.gladiator.level}</td>
                  <td>{friend.gladiator.reputation}</td>
                  <td>
                    <button onClick={() => handleCancelFriendRequest(friend.id)} className={styles.cancelButton}>
                      Cancel Request
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {friends.length > 0 ? (
        <>
          <table className={styles.friendsTable}>
            <thead>
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th>Level</th>
                <th>Reputation</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {friends.map((friend) => (
                <tr key={friend.id}>
                  <td>
                    <img
                      src={`/img/avatars/${friend.gladiator.race?.toLowerCase() ?? 'default'}.${friend.gladiator.sex?.toLowerCase() ?? 'default'}.${friend.gladiator.avatar ?? 1}.png`}
                      alt={friend.name}
                      className={styles.avatar}
                    />
                  </td>
                  <td>
                    <Link to={`/gladiator/${friend.gladiator.id}`} className={styles.profileLink}>
                      {friend.name}
                    </Link>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className={styles.mailIcon}
                      title="Send Mail"
                      onClick={() => openSendMailModal(friend)}
                    />
                  </td>
                  <td>{friend.gladiator.level}</td>
                  <td>{friend.gladiator.reputation}</td>
                  <td>
                    {friend.isOnline ? (
                      <FontAwesomeIcon icon={faCircle} className={styles.online} title="Online" />
                    ) : (
                      <span className={styles.offline}>
                        Last seen: {friend.lastSeen ? new Date(friend.lastSeen).toLocaleString() : 'Offline'}
                      </span>
                    )}
                  </td>
                  <td>
                    <button onClick={() => handleRemoveFriend(friend.id)} className={styles.removeButton}>
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.pagination}>
            <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </>
      ) : (
        <p>No friends found.</p>
      )}

      {/* SendMail Modal */}
      {showSendMail && selectedFriend && (
        <SendMail
          recipientId={selectedFriend.id}
          recipientName={selectedFriend.name}
          onClose={closeSendMailModal}
        />
      )}
    </div>
  );
};

export default FriendsList;
