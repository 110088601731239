import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import Toast from '../Toast';
import LobbyDetails from './LobbyDetails';
import TeamList from './TeamList';
import ChatBox from './ChatBox';
import Preferences from './Preferences';
import ActionButtons from './ActionButtons';
import { useGladiator } from '../../../contexts/GladiatorContext';
import { useAuth } from '../../../contexts/AuthContext';
import styles from './LobbyView.module.css';

const socket = io('https://njordheim.com');

interface TeamMember {
  id: string;
  name: string;
  level: number;
  userId: string;
}

interface Lobby {
  id: string;
  creatorId: string;
  name: string;
  minLevel: number;
  maxLevel: number;
  maxPlayers: number;
  randomTeams: boolean;
  teams: {
    teamOne: TeamMember[];
    teamTwo: TeamMember[];
  };
}

const LobbyView: React.FC = () => {
  const { lobbyId } = useParams<{ lobbyId: string }>();
  const navigate = useNavigate();
  const { gladiator } = useGladiator();
  const { user } = useAuth();

  const [lobby, setLobby] = useState<Lobby | null>(null);
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const [participatingLobbyId, setParticipatingLobbyId] = useState<number | null>(null);
  const [alreadyInLobby, setAlreadyInLobby] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [preferences, setPreferences] = useState({
    surrenderAt: 50,
    strategy: 'Normal',
  });

  useEffect(() => {
    const fetchLobbyData = async () => {
      try {
        const lobbyResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}`, { withCredentials: true });
        setLobby(lobbyResponse.data);

        const participationResponse = await axios.get('/api/team-battle/participation', { withCredentials: true });
        setParticipatingLobbyId(participationResponse.data.lobbyId);
        setAlreadyInLobby(participationResponse.data.inLobby);

        const chatResponse = await axios.get(`/api/team-battle/lobbies/${lobbyId}/chat`, { withCredentials: true });
        setChatMessages(chatResponse.data);
      } catch (error) {
        console.error('Error fetching lobby data:', error);
        setToastMessage('Failed to fetch lobby data.');
        setToastType('error');
      }
    };

    fetchLobbyData();
    socket.emit('join_lobby', { lobbyId });

    socket.on('new_message', (message) => {
      setChatMessages((prev) => [...prev, message]);
    });

    socket.on('lobby_update', (updatedLobby) => {
      setLobby(updatedLobby);
    });

    socket.on('redirect_to_battle', ({ battleId }) => {
      navigate(`/battle-result/${battleId}`);
    });

    return () => {
      socket.emit('leave_lobby', { lobbyId });
      socket.off('new_message');
      socket.off('lobby_update');
      socket.off('redirect_to_battle');
    };
  }, [lobbyId, navigate]);

  const updatePreferences = async (newPreferences: { surrenderAt: number; strategy: string }) => {
    setPreferences(newPreferences);
    try {
      await axios.post(`/api/team-battle/lobbies/${lobbyId}/participant/${gladiator.id}`, newPreferences, {
        withCredentials: true,
      });
      setToastMessage('Preferences updated successfully!');
      setToastType('success');
    } catch (error) {
      console.error('Error updating preferences:', error);
      setToastMessage('Failed to update preferences.');
      setToastType('error');
    }
  };

  const handleJoinTeam = async (team: string) => {
    if (!lobby) return;

    try {
      await axios.post(`/api/team-battle/lobbies/${lobbyId}/join`, { team }, { withCredentials: true });
      setAlreadyInLobby(true);
      setToastMessage('Joined team successfully!');
      setToastType('success');
    } catch (error) {
      console.error('Error joining team:', error);
      setToastMessage('Failed to join the team.');
      setToastType('error');
    }
  };

  const handleStartBattle = async () => {
    if (!lobby) return;

    try {
      const response = await axios.post('/api/battle/team-battle', { lobbyId, preferences }, { withCredentials: true });
      socket.emit('redirect_to_battle', { battleId: response.data.battleId });
      setToastMessage('Battle started successfully!');
      setToastType('success');
    } catch (error) {
      console.error('Error starting battle:', error);
      setToastMessage('Failed to start the battle.');
      setToastType('error');
    }
  };

  return (
    <div className={styles.lobbyViewContainer}>
      {toastMessage && <Toast message={toastMessage} type={toastType} onClose={() => setToastMessage(null)} />}
      {lobby ? (
        <>
          <LobbyDetails lobby={lobby} />
          <Preferences preferences={preferences} onUpdate={updatePreferences} />
          <TeamList lobby={lobby.teams} userId={user.id} isOwner={lobby.creatorId === gladiator.id} onJoinTeam={handleJoinTeam} />
          {lobbyId && <ChatBox chatMessages={chatMessages} lobbyId={lobbyId} socket={socket} />}
          <ActionButtons
            isOwner={lobby.creatorId === gladiator.id}
            alreadyInLobby={alreadyInLobby}
            navigate={navigate}
            lobbyId={lobbyId!}
            handleStartBattle={handleStartBattle}
          />
        </>
      ) : (
        <p>Loading lobby details...</p>
      )}
    </div>
  );
};

export default LobbyView;
