import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSocket } from '../../../contexts/SocketContext';
import { useGladiator } from '../../../contexts/GladiatorContext';
import Toast from '../Toast';
import styles from './WorkDistrict.module.css';

interface NPC {
  id: number;
  name: string;
  description: string;
  rewardOptions: { rounds: number; silverReward: number; reputationReward: number }[];
}

interface Gladiator {
  id: number;
  rounds: number;
  userId: number;
}

const WorkDistrict: React.FC = () => {
  const { socket } = useSocket();
  const [npcs, setNpcs] = useState<NPC[]>([]);
  const [selectedNpc, setSelectedNpc] = useState<NPC | null>(null);
  const [selectedRounds, setSelectedRounds] = useState<number | null>(null);
  const [gladiator, setGladiator] = useState<Gladiator>({ id: 0, rounds: 100, userId: 0 });
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const { gladiatorId } = useGladiator();

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    fetchNpcs();
    fetchGladiatorData();

    if (socket) {
      socket.on('roundsUpdate', (updatedRounds: number) => {
        setGladiator((prev) => ({ ...prev, rounds: updatedRounds }));
      });
    }

    return () => {
      if (socket) socket.off('roundsUpdate');
    };
  }, [socket]);

  const fetchNpcs = () => {
    axios
      .get('/api/work/npcs', { withCredentials: true })
      .then((response) => setNpcs(response.data))
      .catch((error) => {
        console.error('Error fetching NPCs:', error);
        displayToast('Failed to load NPCs. Please try again later.', 'error');
      });
  };

  const fetchGladiatorData = () => {
    axios
      .get(`/api/gladiator/${gladiatorId}`, { withCredentials: true })
      .then((response) => setGladiator(response.data))
      .catch((error) => {
        console.error('Error fetching gladiator data:', error);
        displayToast('Failed to load gladiator data.', 'error');
      });
  };

  const handleNpcSelect = (npc: NPC) => {
    setSelectedNpc(npc);
    setSelectedRounds(null);

    if (socket) {
      socket.emit('requestRoundsUpdate', gladiator.userId);
    }
  };

  const handleWork = () => {
    if (selectedNpc && selectedRounds) {
      axios
        .post('/api/work/earn', { npcId: selectedNpc.id, rounds: selectedRounds, gladiatorId }, { withCredentials: true })
        .then(() => displayToast('Silver earned successfully!', 'success'))
        .catch((error) => {
          console.error('Failed to earn silver:', error);
          displayToast('Failed to earn silver. Please try again later.', 'error');
        });
    }
  };

  return (
    <div className={styles.workDistrictContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h1 className={styles.workTitle}>Work District</h1>
  
      <p className={styles.workDistrictDescription}>
        Welcome to the Work District, gladiator. Within these walls, the sweat and toil of Njordheim’s finest keeps the city alive. Here, you’ll find honest labor—or something close enough—that’ll earn you a few coins, a dash of reputation, and maybe even a bit of honor. But be warned: not all tasks are for the faint-hearted.
        <br /><br />
        Help old blacksmiths with their heavy loads, deliver much-needed supplies to the craftsmen building our city anew, or lend a hand in the infirmary to feed and care for the wounded. For the bold, there are rumors of darker dealings, jobs with no questions asked... but sometimes, the payment comes in whispers and wary glances, rather than shining silver.
        <br /><br />
        Choose your task wisely, gladiator. Each choice marks you in Njordheim's eyes. Are you here to rebuild or to lurk in the shadows?
      </p>
  
      {!selectedNpc ? (
        <div className={styles.npcList}>
          {npcs.map((npc) => (
            <div key={npc.id} onClick={() => handleNpcSelect(npc)} className={styles.npcCard}>
              <h3 className={styles.npcName}>{npc.name}</h3>
              <p className={styles.npcDescription}>{npc.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.selectedNpc}>
          <h2 className={styles.npcName}>{selectedNpc.name}</h2>
          <p className={styles.npcDescription}>{selectedNpc.description}</p>
          <button onClick={() => setSelectedNpc(null)} className={styles.backButton}>Back to NPC List</button>
          
          <div className={styles.selectContainer}>
            <label htmlFor="workOptions" className={styles.selectLabel}>Select Work Option:</label>
            <select
              id="workOptions"
              value={selectedRounds ?? ''}
              onChange={(e) => setSelectedRounds(Number(e.target.value))}
              className={styles.selectMenu}
            >
              <option value="" disabled>Select Rounds</option>
              {selectedNpc.rewardOptions.map((option) => (
                <option
                  key={option.rounds}
                  value={option.rounds}
                  disabled={option.rounds > gladiator.rounds}
                  style={{
                    color: option.rounds > gladiator.rounds ? 'gray' : 'inherit',
                  }}
                >
                  {`${option.rounds} Rounds - `}
                  <span className={styles.greenText}>{`${option.silverReward} Silver`}</span>, 
                  <span className={styles.greenText}>{`${option.reputationReward} Reputation`}</span>
                </option>
              ))}
            </select>
          </div>
          
          <button onClick={handleWork} disabled={!selectedRounds} className={styles.workButton}>
            Work
          </button>
        </div>
      )}
    </div>
  );
};

export default WorkDistrict;
