import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import Toast from '../Toast';
import styles from './Profile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

interface Stats {
  winsTournament: number;
  lossTournament: number;
  winsDuel: number;
  lossDuel: number;
  winsTeamBattle: number;
  lossTeamBattle: number;
  winsClanLeague: number;
  lossClanLeague: number;
  winsPvE: number;
  lossPvE: number;
  tournamentFirstPlace: number;
  tournamentSecondPlace: number;
  tournamentThirdPlace: number;
  highestDamageDealt: number;
  mostDamageDone: number;
  mostDamageTaken: number;
  mostAttacksMade: number;
  mostDodgedAttacks: number;
  mostParries: number;
  mostBlockedAttacks: number;
  mostMissedAttacks: number;
  mostCriticalHits: number;
}

interface Gladiator {
  userId: number;
  name: string;
  race: string;
  age: number;
  sex: string;
  level: number;
  reputation: number;
  avatar: string;
  title: string;
  kills: number;
  stats: Stats;
}

const Profile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const gladiatorId = parseInt(id || '0', 10);
  const [gladiator, setGladiator] = useState<Gladiator | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    const fetchGladiator = async () => {
      try {
        const response = await axios.get(`/api/gladiator/profile/${gladiatorId}`);
        setGladiator(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gladiator profile:", error);
        displayToast("Failed to load gladiator profile.", 'error');
        setLoading(false);
      }
    };
    fetchGladiator();
  }, [gladiatorId]);

  if (loading) return <div>Loading profile...</div>;

  const calculateStats = (wins: number, losses: number) => {
    const total = wins + losses;
    const winPercentage = total > 0 ? ((wins / total) * 100).toFixed(2) : '0';
    return { total, winPercentage };
  };

  const handleSendFriendRequest = async () => {
    try {
      const response = await axios.post(`/api/friend/request`, {
        userId: user?.id,
        friendId: gladiator?.userId,
      });
      if (response.data.success) {
        setIsFriend(true);
        displayToast('Friend request sent successfully!', 'success');
      }
    } catch (error) {
      console.error("Error sending friend request:", error);
      displayToast("Failed to send friend request. Please try again.", 'error');
    }
  };

  return (
    <div className={styles.profileContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      {gladiator ? (
        <>
          <div className={styles.titleContainer}>
            <h1 className={styles.profileTitle}>{gladiator.title} - {gladiator.name}</h1>
            <FontAwesomeIcon
              icon={faUserPlus}
              className={styles.friendRequestIcon}
              onClick={handleSendFriendRequest}
              title="Send Friend Request"
            />
          </div>

          {/* General Information */}
          <div className={styles.profileSection}>
            <h2 className={styles.profileLabel}>General Information</h2>
            <div className={styles.statsContainer}>
              <p><strong>Race:</strong> {gladiator.race}</p>
              <p><strong>Sex:</strong> {gladiator.sex}</p>
              <p><strong>Age:</strong> {gladiator.age}</p>
              <p><strong>Level:</strong> {gladiator.level}</p>
              <p><strong>Reputation:</strong> {gladiator.reputation}</p>
              <p><strong>Kills:</strong> {gladiator.kills}</p>
            </div>
          </div>

          {/* Detailed Combat Stats */}
          <div className={styles.profileSection}>
            <h2 className={styles.profileLabel}>Detailed Combat Stats</h2>
            <table className={styles.statsTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Total Battles</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Win %</th>
                </tr>
              </thead>
              <tbody>
                {[
                  { type: 'PvE', wins: gladiator.stats.winsPvE, losses: gladiator.stats.lossPvE },
                  { type: 'Duel', wins: gladiator.stats.winsDuel, losses: gladiator.stats.lossDuel },
                  { type: 'Team Battle', wins: gladiator.stats.winsTeamBattle, losses: gladiator.stats.lossTeamBattle },
                  { type: 'Tournament', wins: gladiator.stats.winsTournament, losses: gladiator.stats.lossTournament },
                  { type: 'Clan Battle', wins: gladiator.stats.winsClanLeague, losses: gladiator.stats.lossClanLeague },
                ].map((stat) => {
                  const { total, winPercentage } = calculateStats(stat.wins, stat.losses);
                  return (
                    <tr key={stat.type}>
                      <td>{stat.type}</td>
                      <td>{total}</td>
                      <td>{stat.wins}</td>
                      <td>{stat.losses}</td>
                      <td>{winPercentage}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Tournament and Combat Metrics */}
          <div className={styles.profileSection}>
            <h2 className={styles.profileLabel}>Tournament and Combat Metrics</h2>
            <table className={styles.statsTable}>
              <thead>
                <tr>
                  <th>Metric</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>1st Place Finishes</td><td>{gladiator.stats.tournamentFirstPlace}</td></tr>
                <tr><td>2nd Place Finishes</td><td>{gladiator.stats.tournamentSecondPlace}</td></tr>
                <tr><td>3rd Place Finishes</td><td>{gladiator.stats.tournamentThirdPlace}</td></tr>
                <tr><td>Highest Damage Dealt</td><td>{gladiator.stats.highestDamageDealt}</td></tr>
                <tr><td>Most Damage Done</td><td>{gladiator.stats.mostDamageDone}</td></tr>
                <tr><td>Most Damage Taken</td><td>{gladiator.stats.mostDamageTaken}</td></tr>
                <tr><td>Most Attacks Made</td><td>{gladiator.stats.mostAttacksMade}</td></tr>
                <tr><td>Most Dodged Attacks</td><td>{gladiator.stats.mostDodgedAttacks}</td></tr>
                <tr><td>Most Parries</td><td>{gladiator.stats.mostParries}</td></tr>
                <tr><td>Most Blocked Attacks</td><td>{gladiator.stats.mostBlockedAttacks}</td></tr>
                <tr><td>Most Missed Attacks</td><td>{gladiator.stats.mostMissedAttacks}</td></tr>
                <tr><td>Most Critical Hits</td><td>{gladiator.stats.mostCriticalHits}</td></tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p>Gladiator not found.</p>
      )}
    </div>
  );
};

export default Profile;
