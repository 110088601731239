import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toast from '../Toast';
import styles from './CreateLobby.module.css';
import { useGladiator } from '../../../contexts/GladiatorContext';

const CreateLobby: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [minLevel, setMinLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(100);
  const [maxPlayers, setMaxPlayers] = useState<number>(4);
  const [randomTeams, setRandomTeams] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { gladiator } = useGladiator();
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    if (gladiator) {
      setMinLevel(Math.max(1, gladiator.level - 2));
      setMaxLevel(gladiator.level + 4);
    }
  }, [gladiator]);

  const handleCreateLobby = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const lobbyName = name.trim() === '' ? `${gladiator.name}'s Lobby` : name;

    try {
      const response = await axios.post(
        '/api/team-battle/create',
        {
          name: lobbyName,
          minLevel,
          maxLevel,
          maxPlayers,
          randomTeams,
        },
        { withCredentials: true }
      );

      navigate(`/team-battle/lobby/${response.data.lobby.id}`);
      displayToast('Lobby created successfully!', 'success');
    } catch (err) {
      console.error('Error creating lobby:', err);
      displayToast('Failed to create lobby. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const generateLevelOptions = (min: number, max: number) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  };

  return (
    <div className={styles.createLobbyContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h2 className={styles.title}>Create a New Lobby</h2>
      <form onSubmit={handleCreateLobby} className={styles.createLobbyForm}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Lobby Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`${gladiator.name}'s Lobby`}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="minLevel">Min Level:</label>
          <select
            id="minLevel"
            value={minLevel}
            onChange={(e) => setMinLevel(parseInt(e.target.value))}
            required
          >
            {generateLevelOptions(Math.max(1, gladiator.level - 2), gladiator.level)}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="maxLevel">Max Level:</label>
          <select
            id="maxLevel"
            value={maxLevel}
            onChange={(e) => setMaxLevel(parseInt(e.target.value))}
            required
          >
            {generateLevelOptions(gladiator.level, gladiator.level + 4)}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="maxPlayers">Team Size:</label>
          <select
            id="maxPlayers"
            value={maxPlayers}
            onChange={(e) => setMaxPlayers(parseInt(e.target.value))}
            required
          >
            <option value={4}>2 vs. 2</option>
            <option value={6}>3 vs. 3</option>
            <option value={8}>4 vs. 4</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="randomTeams">Random Teams:</label>
          <input
            type="checkbox"
            id="randomTeams"
            checked={randomTeams}
            onChange={(e) => setRandomTeams(e.target.checked)}
          />
        </div>

        <button type="submit" className={styles.createLobbyButton} disabled={isLoading}>
          {isLoading ? 'Creating...' : 'Create Lobby'}
        </button>
      </form>
    </div>
  );
};

export default CreateLobby;
