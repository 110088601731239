import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';

interface RaceStat {
  race: string;
  gladiatorsCreated: number;
  gladiatorsDied: number;
  deathLevel: number;
  deathAge: number;
  raceLegends: number;
  season: { name: string };
}

const RaceMetrics: React.FC = () => {
  const [raceStats, setRaceStats] = useState<RaceStat[]>([]);

  useEffect(() => {
    const fetchRaceStats = async () => {
      try {
        const response = await axios.get('/api/admin/race-insights');
        setRaceStats(response.data);
      } catch (error) {
        console.error('Error fetching race stats:', error);
      }
    };
    fetchRaceStats();
  }, []);

  const data = {
    labels: raceStats.map((stat) => `${stat.race}`),
    datasets: [
      {
        label: 'Gladiators Created',
        backgroundColor: '#00bfff',
        data: raceStats.map((stat) => stat.gladiatorsCreated),
      },
      {
        label: 'Gladiators Died',
        backgroundColor: '#ff4500',
        data: raceStats.map((stat) => stat.gladiatorsDied),
      },
      {
        label: 'Legends',
        backgroundColor: '#ffd700',
        data: raceStats.map((stat) => stat.raceLegends),
      },
    ],
  };

  return (
    <div className="metric-card">
      <h3>Race Performance</h3>
      <div className="chart-container">
        <Bar data={data} />
      </div>
    </div>
  );
};

export default RaceMetrics;
