import React, { useEffect, useState } from 'react';
import { useGladiator } from '../../../contexts/GladiatorContext';
import axios from 'axios';
import styles from './GladiatorProfile.module.css';

interface Weapon {
  id: number;
  name: string;
  minDamage: number;
  maxDamage: number;
  critChance: number;
  critDamage: number;
  weaponType: string;
  weight: number;
}

interface Gladiator {
  id: number
  name: string;
  race: string;
  sex: string;
  avatar: string;
  level: number;
  experience: number;
  nextExperience: number;
  health: number;
  maxHealth: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  primarySkillPoints: number;
  secondarySkillPoints: number;
  silver: number;
  form: number;
  totalCritChance: number;
  totalCritDamage: number;
  weaponSkills: { [key: string]: number };
  weapons: {
    mainHand?: Weapon | null;
    offHand?: Weapon | null;
    twoHand?: Weapon | null;
    shield?: Weapon | null;
  };
}

const GladiatorProfile: React.FC = () => {
  const [gladiator, setGladiator] = useState<Gladiator | null>(null);
  const { gladiatorId } = useGladiator();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGladiator = async () => {
      try {
        const response = await axios.get(`/api/gladiator/${gladiatorId}`, { withCredentials: true });
        setGladiator(response.data);
      } catch (error) {
        setError('Failed to fetch gladiator data');
        console.error(error);
      }
    };
    fetchGladiator();
  }, []);

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  if (!gladiator) {
    return <div className={styles.loadingMessage}>Loading...</div>;
  }

  return (
    <div className={styles.gladiatorProfileContainer}>
      <h1 className={styles.profileTitle}>Gladiator Profile</h1>

      <div className={styles.profileSection}>
        <h2>Basic Information</h2>
        <p><strong>Name:</strong> {gladiator.name}</p>
        <p><strong>Race:</strong> {gladiator.race}</p>
        <p><strong>Sex:</strong> {gladiator.sex}</p>
        <p><strong>Level:</strong> {gladiator.level}</p>
        <p><strong>Experience:</strong> {gladiator.experience} / {gladiator.nextExperience}</p>
        <p><strong>Health:</strong> {gladiator.health}/{gladiator.maxHealth}</p>
        <p><strong>Silver:</strong> {gladiator.silver}</p>
        <p><strong>Form:</strong> {gladiator.form}%</p>
      </div>

      <div className={styles.profileSection}>
        <h2>Attributes</h2>
        <p><strong>Strength:</strong> {gladiator.strength}</p>
        <p><strong>Endurance:</strong> {gladiator.endurance}</p>
        <p><strong>Initiative:</strong> {gladiator.initiative}</p>
        <p><strong>Dodge:</strong> {gladiator.dodge}</p>
        <p><strong>Primary Skill Points:</strong> {gladiator.primarySkillPoints}</p>
        <p><strong>Secondary Skill Points:</strong> {gladiator.secondarySkillPoints}</p>
        <p><strong>Total Crit Chance:</strong> {gladiator.totalCritChance}%</p>
        <p><strong>Total Crit Damage:</strong> {gladiator.totalCritDamage}%</p>
      </div>

      <div className={styles.profileSection}>
        <h2>Weapon Skills</h2>
        {Object.entries(gladiator.weaponSkills).map(([skill, value]) => (
          <p key={skill}><strong>{skill.charAt(0).toUpperCase() + skill.slice(1)} Skill:</strong> {value}</p>
        ))}
      </div>

      <div className={styles.profileSection}>
        <h2>Equipment</h2>
        {['mainHand', 'offHand', 'twoHand', 'shield'].map((slot) => {
          const weapon = gladiator.weapons[slot as keyof Gladiator['weapons']];
          return weapon ? (
            <div key={slot} className={styles.equipmentSlot}>
              <h3>{slot.charAt(0).toUpperCase() + slot.slice(1)}:</h3>
              <p><strong>Name:</strong> {weapon.name}</p>
              <p><strong>Type:</strong> {weapon.weaponType}</p>
              <p><strong>Damage:</strong> {weapon.minDamage} - {weapon.maxDamage}</p>
              <p><strong>Crit Chance:</strong> {weapon.critChance}%</p>
              <p><strong>Crit Damage:</strong> {weapon.critDamage}%</p>
              <p><strong>Weight:</strong> {weapon.weight}</p>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default GladiatorProfile;
