import React from 'react';
import styles from './LobbyDetails.module.css';

interface Lobby {
  name: string;
  minLevel: number;
  maxLevel: number;
  maxPlayers: number;
  randomTeams: boolean;
}

const LobbyDetails: React.FC<{ lobby: Lobby }> = ({ lobby }) => (
  <div className={styles.lobbyDetails}>
    <h1>{lobby.name}</h1>
    <p>Level Range: {lobby.minLevel} - {lobby.maxLevel}</p>
    <p>Max Players: {lobby.maxPlayers}</p>
    <p>Random Teams: {lobby.randomTeams ? 'Yes' : 'No'}</p>
  </div>
);

export default LobbyDetails;
