// BattleResult.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useGladiator } from '../../contexts/GladiatorContext';

const BattleResult: React.FC = () => {
  const { gladiator } = useGladiator();
  const { battleId } = useParams<Record<string, string>>();
  const playerGladiatorId = gladiator?.id || 0;
  const [battleResult, setBattleResult] = useState<any>(null);

  // Function to fetch the battle result
  const fetchBattleResult = async () => {
    try {
      const response = await axios.get(`https://njordheim.com/api/battle/result/${battleId}`, { withCredentials: true });
      setBattleResult(response.data);
    } catch (error) {
      console.error('Error fetching battle result:', error);
      setBattleResult('Error fetching battle result.');
    }
  };

  useEffect(() => {
    if (!battleResult) {
      fetchBattleResult();
    }
  }, [battleId, battleResult]);

  const parseLogWithColors = (log: string) => {
    return log.split(/(\{\{.*?\}\})/).map((segment, i) => {
      const match = segment.match(/\{\{(.*?)\}\}/);
      if (match) {
        const content = match[1];
        const parts = content.split(':');
  
        // If the segment has 3 parts, extract and style the name only
        if (parts.length === 3) {
          const [type, id, name] = parts;
          const displayName = name;
  
          if (id === String(playerGladiatorId) && name === gladiator?.name) {
            return <span key={i} style={{ color: '#64a863' }}>{displayName}</span>; // Green for the player
          } else if (type === 'teamOne') {
            return <span key={i} style={{ color: '#1E90FF' }}>{displayName}</span>; // Blue for Team One
          } else if (type === 'teamTwo') {
            return <span key={i} style={{ color: '#ca2626' }}>{displayName}</span>; // Red for Team Two
          }
        }
  
        // If the segment has 2 parts, use the default colors for teams
        if (parts.length === 2) {
          const [type, name] = parts;
          if (type === 'teamOne') {
            return <span key={i} style={{ color: '#1E90FF' }}>{name}</span>; // Blue for Team One
          } else if (type === 'teamTwo') {
            return <span key={i} style={{ color: '#ca2626' }}>{name}</span>; // Red for Team Two
          }
        }
        
        // Default fallback for unmatched names
        return <span key={i} style={{ color: '#ca2626' }}>{parts[parts.length - 1]}</span>;
      }
      return <span key={i}>{segment}</span>;
    });
  };
  

  const renderSummaryTable = (teamType: string, teamSummary: any, participantSummaries: any) => (
    <div style={{ width: '48%' }}>
      <h4>{teamType === 'teamOne' ? 'Team One' : 'Team Two'}</h4>
      <table style={{
        width: '100%',
        marginBottom: '20px',
        textAlign: 'left',
        borderCollapse: 'collapse',
      }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Name</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Total Damage</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Largest Hit</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Damage Taken</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Attacks Made</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Dodges</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Parries</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Blocks</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Missed</th>
            <th style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>Critical</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(participantSummaries).map((participantKey) => {
            const participant = participantSummaries[participantKey];
            if (participant.team === teamType) {
              return (
                <tr key={participantKey}>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.name}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.damage}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.highestDamageDealt}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.damageTaken}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.attacksMade}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.dodgedAttacks}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.parries}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.blockedAttacks}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.missedAttacks}</td>
                  <td style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>{participant.criticalHits}</td>
                </tr>
              );
            }
            return null;
          })}
          <tr>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>Team Total</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.totalDamage}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.highestDamageDealt}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.damageTaken}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.attacksMade}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.dodgedAttacks}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.parries}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.blockedAttacks}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.missedAttacks}</strong></td>
            <td style={{ borderTop: '1px solid #ccc', padding: '8px' }}><strong>{teamSummary.criticalHits}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div style={{ color: '#f0f0f0' }}>
      <h2>Battle Result</h2>
      {battleResult ? (
        <div>
          {battleResult.reportData.combatLog.map((log: string, index: number) => (
            <p key={index}>{parseLogWithColors(log)}</p>
          ))}
          <h3>{battleResult.reportData.winner === 'teamOne' ? 'Team One Wins!' : 'Team Two Wins!'}</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {renderSummaryTable('teamOne', battleResult.reportData.teamSummaries.teamOne, battleResult.reportData.participantSummaries)}
            {renderSummaryTable('teamTwo', battleResult.reportData.teamSummaries.teamTwo, battleResult.reportData.participantSummaries)}
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BattleResult;
