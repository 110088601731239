import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BeastStats {
  winsVsDualWield: number;
  winsVsShield: number;
  winsVsTwoHand: number;
  winsVsOther: number;
  lossVsDualWield: number;
  lossVsShield: number;
  lossVsTwoHand: number;
  lossVsOther: number;
  kills: number;
}

const BeastMetrics: React.FC = () => {
  const [beastStats, setBeastStats] = useState<BeastStats | null>(null);

  useEffect(() => {
    const fetchBeastStats = async () => {
      try {
        const response = await axios.get('/api/admin/beast-stats');
        setBeastStats(response.data);
      } catch (error) {
        console.error('Failed to fetch beast stats:', error);
      }
    };

    fetchBeastStats();
  }, []);

  if (!beastStats) {
    return <div>Loading Beast Metrics...</div>;
  }

  const winsData = {
    labels: ['Dual Wield', 'Shield', 'Two Handed', 'Other'],
    datasets: [
      {
        label: 'Wins',
        data: [beastStats.winsVsDualWield, beastStats.winsVsShield, beastStats.winsVsTwoHand, beastStats.winsVsOther],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Losses',
        data: [beastStats.lossVsDualWield, beastStats.lossVsShield, beastStats.lossVsTwoHand, beastStats.lossVsOther],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
    ],
  };

  return (
    <div className="metric-card">
      <h3>Beast Performance Metrics</h3>

      <div className="chart-container">
        <Bar
          data={winsData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Wins vs Losses by Gladiator Strategy',
              },
            },
          }}
        />
      </div>

      <div className="beast-stats-summary">
        <h4>Total Kills</h4>
        <p>{beastStats.kills}</p>
      </div>
    </div>
  );
};

export default BeastMetrics;
