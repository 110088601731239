import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Toast from '../Toast';
import styles from './Ranking.module.css';

interface Gladiator {
  id: number;
  name: string;
  level: number;
  experience: number;
  kills: number;
}

const Ranking: React.FC = () => {
  const [gladiators, setGladiators] = useState<Gladiator[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    fetchRanking(currentPage);
  }, [currentPage]);

  const fetchRanking = async (page: number) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/gladiator/ranking', {
        params: { page },
        withCredentials: true,
      });

      setGladiators(response.data.gladiators);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching ranking:', error);
      displayToast('Failed to load gladiator rankings. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className={styles.rankingContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h1 className={styles.rankingTitle}>Gladiator Rankings</h1>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className={styles.rankingTable}>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Level</th>
                <th>Experience</th>
                <th>Kills</th>
              </tr>
            </thead>
            <tbody>
              {gladiators.map((gladiator, index) => (
                <tr key={gladiator.id}>
                  <td>{(currentPage - 1) * 20 + index + 1}</td>
                  <td>
                    <Link to={`/gladiator/${gladiator.id}`} className={styles.profileLink}>
                      {gladiator.name}
                    </Link>
                  </td>
                  <td>{gladiator.level}</td>
                  <td>{gladiator.experience}</td>
                  <td>{gladiator.kills}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={styles.paginationContainer}>
            <button
              className={styles.paginationButton}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className={styles.paginationInfo}>
              {currentPage}/{totalPages}
            </span>
            <button
              className={styles.paginationButton}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Ranking;
