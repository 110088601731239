import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGladiator } from '../../../contexts/GladiatorContext';
import Toast from '../Toast';
import styles from './Duel.module.css';

const Duel: React.FC = () => {
  const { gladiator } = useGladiator();
  const [maxLevel, setMaxLevel] = useState<number>(gladiator ? gladiator.level + 2 : 1);
  const [strategy, setStrategy] = useState<string>(() => {
    const savedStrategy = localStorage.getItem('duelStrategy');
    return savedStrategy || 'Normal';
  });
  const [surrenderHP, setSurrenderHP] = useState<number>(() => {
    const savedSurrenderValue = localStorage.getItem('duelSurrenderAt');
    return savedSurrenderValue ? parseInt(savedSurrenderValue) : 50;
  });
  const [inQueue, setInQueue] = useState<boolean>(false);
  const [healMessage, setHealMessage] = useState<string>('');
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const toggleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (gladiator) {
      setMaxLevel(gladiator.level + 2);
      checkQueueStatus();
      checkHealStatus();
    }
  }, [gladiator]);

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const checkQueueStatus = async () => {
    try {
      const response = await axios.get(`/api/duel/queue/status/${gladiator.id}`);
      if (response.data.inQueue) {
        setInQueue(true);
        setStrategy(response.data.strategy);
        setSurrenderHP(response.data.surrenderHP * 100);
        setMaxLevel(response.data.maxLevel);
      }
    } catch {
      displayToast('Error checking queue status.', 'error');
    }
  };

  const checkHealStatus = async () => {
    try {
      const response = await axios.get(`/api/duel/heal-status/${gladiator.id}`);
      setHealMessage(response.data.message);
    } catch {
      displayToast('Error checking heal status.', 'error');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!gladiator) {
      displayToast('No gladiator selected.', 'error');
      return;
    }

    try {
      const response = await axios.post(
        '/api/battle/duel/queue',
        { gladiatorId: gladiator.id, strategy, surrenderHP: surrenderHP / 100, minLevel: gladiator.level, maxLevel },
        { withCredentials: true }
      );

      if (response.data.battleId) {
        navigate(`/battle-result/${response.data.battleId}`);
      } else {
        setInQueue(true);
        displayToast(response.data.message || 'Queued for a duel. Waiting for an opponent.', 'success');
      }
    } catch {
      displayToast('Error queuing for duel. Please try again later.', 'error');
    }
  };

  const handleLeaveQueue = async () => {
    try {
      await axios.post(`/api/duel/queue/leave/${gladiator.id}`, {}, { withCredentials: true });
      setInQueue(false);
      displayToast('Left the queue.', 'success');
    } catch {
      displayToast('Failed to leave the queue.', 'error');
    }
  };

  useEffect(() => localStorage.setItem('duelSurrenderAt', surrenderHP.toString()), [surrenderHP]);
  useEffect(() => localStorage.setItem('duelStrategy', strategy), [strategy]);

  if (!gladiator) {
    return <p className={styles.errorMessage}>No gladiator available. Please select a gladiator.</p>;
  }

  return (
    <div className={styles.duelContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h1>Queue for a Duel</h1>
      <p>In the heart of Njordheim, beneath the shadow of the Emperor's citadel, lies the grand arena...</p>
      {showMore && (
        <>
          <p>Duels are fought with honor and cunning...</p>
          <p>Each combatant sets their surrender threshold...</p>
          <p>The duel system operates on a waiting queue...</p>
        </>
      )}
      <button className={styles.toggleTextButton} onClick={toggleShowMore}>
        {showMore ? 'Read less...' : 'Read more...'}
      </button>

      {!inQueue ? (
        <form onSubmit={handleSubmit} className={styles.duelForm}>
          <div className={styles.formGroup}>
            <label htmlFor="strategy">Strategy:</label>
            <select id="strategy" value={strategy} onChange={(e) => setStrategy(e.target.value)} className={styles.formControl}>
              <option value="Normal">Normal</option>
              <option value="Normal: Light Attacks">Normal: Light Attacks</option>
              <option value="Normal: Heavy Attacks">Normal: Heavy Attacks</option>
              <option value="Offensive">Offensive</option>
              <option value="Offensive: Light Attacks">Offensive: Light Attacks</option>
              <option value="Offensive: Heavy Attacks">Offensive: Heavy Attacks</option>
              <option value="Defensive">Defensive</option>
              <option value="Defensive: Light Attacks">Defensive: Light Attacks</option>
              <option value="Defensive: Heavy Attacks">Defensive: Heavy Attacks</option>
              <option value="Berserk">Berserk</option>
              <option value="Berserk: Light Attacks">Berserk: Light Attacks</option>
              <option value="Berserk: Heavy Attacks">Berserk: Heavy Attacks</option>
            </select>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="surrenderHP">Surrender HP (%):</label>
            <select id="surrenderHP" value={surrenderHP} onChange={(e) => setSurrenderHP(parseInt(e.target.value))} className={styles.formControl}>
              {[90, 80, 70, 60, 50, 40, 30, 20, 10, 0].map((value) => (
                <option key={value} value={value}>{value}%</option>
              ))}
            </select>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="maxLevel">Maximum Level:</label>
            <input id="maxLevel" type="number" value={maxLevel} min={gladiator.level} max={gladiator.level + 5} onChange={(e) => setMaxLevel(parseInt(e.target.value))} className={styles.formControl} required />
          </div>

          {healMessage && <p className={styles.healMessage}>{healMessage}</p>}

          <button type="submit" className={styles.submitButton}>Queue for Duel</button>
        </form>
      ) : (
        <div className={styles.queueInfo}>
          <p>You are queued with the following settings:</p>
          <p>Strategy: {strategy}</p>
          <p>Surrender HP: {surrenderHP}%</p>
          <p>Max Opponent Level: {maxLevel}</p>
          <p>{healMessage}</p>
          <button className={styles.leaveButton} onClick={handleLeaveQueue}>Leave Queue</button>
        </div>
      )}
    </div>
  );
};

export default Duel;
