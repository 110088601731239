import React from 'react';
import styles from './ActionButtons.module.css';

interface ActionButtonsProps {
  isOwner: boolean;
  alreadyInLobby: boolean;
  navigate: (path: string) => void;
  lobbyId: string;
  handleStartBattle: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isOwner,
  alreadyInLobby,
  navigate,
  lobbyId,
  handleStartBattle,
}) => (
  <div className={styles.actionButtons}>
    {isOwner && <button onClick={handleStartBattle}>Start Battle</button>}
    {alreadyInLobby && <button onClick={() => navigate('/team-battle')}>Leave Lobby</button>}
    <button onClick={() => navigate('/team-battle')}>Back to Lobbies</button>
  </div>
);

export default ActionButtons;
