import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGladiator } from '../../../contexts/GladiatorContext';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import Tooltip from '../ItemTooltip';
import Toast from '../Toast';
import { Item } from '../../types/Item';
import styles from './Inventory.module.css';

interface ActivePotion {
  name: string;
  effect: string;
  effectEndTime: string;
}

interface PotionWithTimeLeft extends ActivePotion {
  hours: number;
  minutes: number;
  seconds: number;
}

const Inventory: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [activePotions, setActivePotions] = useState<PotionWithTimeLeft[]>([]);
  const { gladiatorId } = useGladiator();
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  const fetchItems = async () => {
    try {
      const response = await axios.get(`/api/inventory/${gladiatorId}`, { withCredentials: true });
      setItems(response.data);
    } catch (error) {
      displayToast('Error fetching inventory items.', 'error');
    }
  };

  const fetchActivePotions = async () => {
    try {
      const response = await axios.get(`/api/inventory/${gladiatorId}/activePotions`, { withCredentials: true });
      const potions = response.data.map((potion: ActivePotion) => calculateTimeLeft(potion));
      setActivePotions(potions);
    } catch (error) {
      displayToast('Error fetching active potions.', 'error');
    }
  };

  const calculateTimeLeft = (potion: ActivePotion) => {
    const effectEndTime = new Date(potion.effectEndTime).getTime();
    const currentTime = new Date().getTime();
    const remainingTime = effectEndTime - currentTime;

    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    return { ...potion, hours, minutes, seconds };
  };

  useEffect(() => {
    fetchItems();
    fetchActivePotions();

    const interval = setInterval(() => {
      setActivePotions((prevPotions) => prevPotions.map(calculateTimeLeft));
    }, 1000);

    return () => clearInterval(interval);
  }, [gladiatorId]);

  const handleEquip = async (itemId: number, slot: string) => {
    try {
      const response = await axios.post(`/api/inventory/equip`, { gladiatorId, itemId, slot }, { withCredentials: true });
      if (response.status === 200) {
        await fetchItems();
        displayToast('Item equipped successfully.', 'success');
      }
    } catch (error) {
      displayToast('Error equipping item.', 'error');
    }
  };

  const handleUnequip = async (itemId: number, slot: string) => {
    try {
      const response = await axios.post(`/api/inventory/unequip`, { gladiatorId, itemId, slot }, { withCredentials: true });
      if (response.status === 200) {
        await fetchItems();
        displayToast('Item unequipped successfully.', 'success');
      }
    } catch (error) {
      displayToast('Error unequipping item.', 'error');
    }
  };

  const handleUsePotion = async (itemId: number) => {
    try {
      const response = await axios.post(`/api/inventory/usePotion`, { gladiatorId, itemId }, { withCredentials: true });
      if (response.status === 200) {
        await fetchItems();
        await fetchActivePotions();
        displayToast('Potion used successfully.', 'success');
      }
    } catch (error) {
      displayToast('Error using potion.', 'error');
    }
  };

  const handleUseKnowledgeItem = async (itemId: number) => {
    try {
      const response = await axios.post(`/api/craft/unlock-blueprint`, { gladiatorId, itemId }, { withCredentials: true });
      if (response.status === 200) {
        displayToast('Blueprint unlocked successfully!', 'success');
        await fetchItems();
      }
    } catch (error) {
      displayToast('Failed to unlock blueprint.', 'error');
    }
  };

  const equippedItems = items.filter((item) => item.equipped);
  const unequippedItems = items.filter((item) => !item.equipped && item.itemCategory !== 'Material' && item.itemCategory !== 'Potion' && item.itemCategory !== 'Knowledge');
  const potions = items.filter((item) => item.itemCategory === 'Potion');
  const materials = items.filter((item) => item.itemCategory === 'Material');
  const knowledgeItems = items.filter((item) => item.itemCategory === 'Knowledge');

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
  
    if (!destination) return;
  
    const [draggedItemId, draggedItemSlot] = draggableId.split('-');
    const itemId = parseInt(draggedItemId, 10);
  
    const draggedItem =
      source.droppableId === 'inventory'
        ? unequippedItems.find((item) => item.id === itemId)
        : equippedItems.find((item) => item.id === itemId && item.slot === draggedItemSlot);
  
    if (!draggedItem) {
      displayToast('Dragged item not found.', 'error');
      return;
    }
  
    const destinationSlot = destination.droppableId.replace('equipped-', '');
  
    if (destination.droppableId === 'inventory') {
      handleUnequip(draggedItem.id, draggedItem.slot);
    } else {
      if (draggedItem.itemCategory === 'Weapon') {
        if (draggedItem.slot === 'twoHand') {
          handleEquip(draggedItem.id, 'twoHand');
        } else if (draggedItem.slot === 'oneHand') {
          if (destinationSlot === 'mainHand' || (destinationSlot === 'offHand' && draggedItem.canEquipInOffHand)) {
            handleEquip(draggedItem.id, destinationSlot);
          }
        }
      } else if (draggedItem.itemCategory === 'Shield') {
        if (destinationSlot === 'offHand') {
          handleEquip(draggedItem.id, 'offHand');
        }
      } else {
        handleEquip(draggedItem.id, destinationSlot);
      }
    }
  };

  const equippedCategories = [
    { id: 'Head', label: 'Head', className: styles.head, isSmallItem: false },
    { id: 'Torso', label: 'Torso', className: styles.torso, isSmallItem: false },
    { id: 'Legs', label: 'Legs', className: styles.legs, isSmallItem: false },
    { id: 'Feet', label: 'Feet', className: styles.feet, isSmallItem: false },
    { id: 'mainHand', label: 'Main Hand', className: styles.mainHand, isSmallItem: false },
    { id: 'offHand', label: 'Off Hand', className: styles.offHand, isSmallItem: false },
    { id: 'Hands', label: 'Hands', className: styles.hands, isSmallItem: false },
    { id: 'Shoulders', label: 'Shoulders', className: styles.shoulders, isSmallItem: false },
    { id: 'Amulet', label: 'Amulet', className: `${styles.amulet} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Armring', label: 'Armring', className: `${styles.armring} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Cloak', label: 'Cloak', className: `${styles.cloak} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Neck', label: 'Neck', className: `${styles.neck} ${styles.smallItemSlot}`, isSmallItem: true },
    { id: 'Ring', label: 'Ring', className: `${styles.ring} ${styles.smallItemSlot}`, isSmallItem: true },
  ];

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.inventoryContainer}>
        {toastMessage && (
          <Toast
            message={toastMessage}
            type={toastType}
            duration={3000}
            onClose={() => setToastMessage(null)}
          />
        )}
        
        {/* Active Potions Section */}
        <div className={styles.activePotionsSection}>
          <h2>Active Potions</h2>
          {activePotions.length > 0 ? (
            <ul className={styles.potionList}>
              {activePotions.map((potion, index) => (
                <li key={index} className={styles.potionListItem}>
                  <strong>{potion.name}</strong>
                  <ul>
                    {potion.effect.split(',').map((effect, i) => (
                      <li key={i}>{effect.trim()}</li>
                    ))}
                  </ul>
                  Time left: {potion.hours}h {potion.minutes}m {potion.seconds}s
                </li>
              ))}
            </ul>
          ) : (
            <p>No active potions.</p>
          )}
        </div>

        {/* Equipped Items Section */}
        <div className={styles.equippedItemsSection}>
          <h2>Equipped Items</h2>
          <div className={styles.equippedGrid}>
            {equippedCategories.map((category) => (
              category.isSmallItem ? null : (
                <Droppable key={category.id} droppableId={`equipped-${category.id}`}>
                  {(provided) => (
                    <div className={`${styles.equippedSlot} ${category.className}`} ref={provided.innerRef} {...provided.droppableProps}>
                      <h3>{category.label}</h3>
                      <div className={styles.equippedCategory}>
                        {equippedItems
                          .filter((item) =>
                            item.slot === category.id ||
                            (item.slot === 'twoHand' && (category.id === 'mainHand' || category.id === 'offHand'))
                          )
                          .map((item, index) => (
                            <Draggable key={`${item.id}-${item.slot}`} draggableId={`${item.id}-${item.slot}`} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.inventoryItem}
                                >
                                  <Tooltip itemId={item.id}>
                                    <img src={`/img/items/${item.name || 'no_img'}.png`} alt={item.name} className={styles.itemIcon} onError={(e) => {
                                      const target = e.target as HTMLImageElement;
                                      target.onerror = null;
                                      target.src = '/img/items/no_img.png';
                                    }} />
                                  </Tooltip>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )
            ))}
          </div>

          <div className={styles.equippedSmallItemsGrid}>
            {equippedCategories.map(category => (
              category.isSmallItem && (
                <Droppable key={category.id} droppableId={`equipped-${category.id}`}>
                  {(provided) => (
                    <div className={`${styles.equippedSlot} ${styles.smallItemSlot}`} ref={provided.innerRef} {...provided.droppableProps}>
                      <h3>{category.label}</h3>
                      <div className={styles.equippedCategory}>
                        {equippedItems
                          .filter(item => item.slot === category.id)
                          .map((item, index) => (
                            <Draggable key={`${item.id}-${item.slot}`} draggableId={`${item.id}-${item.slot}`} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.inventoryItem}
                                >
                                  <Tooltip itemId={item.id}>
                                    <img src={`/img/items/${item.name || 'no_img'}.png`} alt={item.name} className={styles.itemIcon} onError={(e) => {
                                      const target = e.target as HTMLImageElement;
                                      target.onerror = null;
                                      target.src = '/img/items/no_img.png';
                                    }} />
                                  </Tooltip>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )
            ))}
          </div>
        </div>

        {/* Unequipped Items Section */}
        <div className={styles.inventorySection}>
          <h2>Unequipped Items</h2>
          <h3>Equipment</h3>
          <Droppable droppableId="inventory">
            {(provided) => (
              <ul className={styles.inventoryList} ref={provided.innerRef} {...provided.droppableProps}>
                {unequippedItems.map((item, index) => (
                  <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.inventoryListItem}
                      >
                        <Tooltip itemId={item.id}>
                          <img src={`/img/items/${item.name || 'no_img'}.png`} alt={item.name} className={styles.itemIcon} onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = '/img/items/no_img.png';
                          }} />
                          <span>{item.name} - Qty: {item.quantity}</span>
                        </Tooltip>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>

          {/* Potions Category */}
          <h3>Potions</h3>
          <Droppable droppableId="potions">
            {(provided) => (
              <ul className={styles.inventoryList} ref={provided.innerRef} {...provided.droppableProps}>
                {potions.map((item, index) => (
                  <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.inventoryListItem}
                      >
                        <Tooltip itemId={item.id}>
                          <img src={`/img/items/${item.name || 'no_img'}.png`} alt={item.name} className={styles.itemIcon} onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = '/img/items/no_img.png';
                          }} />
                          <span>{item.name} - Qty: {item.quantity}</span>
                        </Tooltip>
                        <button onClick={() => handleUsePotion(item.id)} className={styles.inventoryButton}>
                          Use
                        </button>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>

          {/* Materials Category */}
          <h3>Materials</h3>
          <Droppable droppableId="materials">
            {(provided) => (
              <ul className={styles.inventoryList} ref={provided.innerRef} {...provided.droppableProps}>
                {materials.map((item, index) => (
                  <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.inventoryListItem}
                      >
                        <Tooltip itemId={item.id}>
                          <img src={`/img/items/${item.name || 'no_img'}.png`} alt={item.name} className={styles.itemIcon} onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = '/img/items/no_img.png';
                          }} />
                          <span>{item.name} - Qty: {item.quantity}</span>
                        </Tooltip>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>

          {/* Knowledge Category */}
          <h3>Knowledge</h3>
          <Droppable droppableId="knowledge">
            {(provided) => (
              <ul className={styles.inventoryList} ref={provided.innerRef} {...provided.droppableProps}>
                {knowledgeItems.map((item, index) => (
                  <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.inventoryListItem}
                      >
                        <Tooltip itemId={item.id}>
                          <img src={`/img/items/${item.name || 'no_img'}.png`} alt={item.name} className={styles.itemIcon} onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = '/img/items/no_img.png';
                          }} />
                          <span>{item.name} - Qty: {item.quantity}</span>
                        </Tooltip>
                        <button onClick={() => handleUseKnowledgeItem(item.id)} className={styles.inventoryButton}>
                          Use
                        </button>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default Inventory;
