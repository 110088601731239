import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useGladiator } from '../../../contexts/GladiatorContext';
import Tooltip from '../ItemTooltip';
import Toast from '../Toast';
import { Item } from '../../types/Item';
import styles from './Market.module.css';

interface Vendor {
  id: number;
  name: string;
  description: string;
}

interface VendorInventory {
  id: number;
  vendorId: number;
  itemId: number;
  stock: number;
  price: number;
  itemCategory: string;
  itemSubCategory: string;
  img?: string;
  itemDetails: Item;
}

const Marketplace: React.FC = () => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>('Weapon');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
  const [items, setItems] = useState<VendorInventory[]>([]);
  const [loadingVendors, setLoadingVendors] = useState<boolean>(true);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const { gladiatorId } = useGladiator();
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = () => {
    setLoadingVendors(true);
    axios
      .get('/api/market/vendors', { withCredentials: true })
      .then((response) => {
        setVendors(response.data);
        setLoadingVendors(false);
      })
      .catch((error) => {
        console.error('Error fetching vendors:', error);
        displayToast('Failed to load vendors.', 'error');
        setLoadingVendors(false);
      });
  };

  const handleVendorSelect = (vendor: Vendor) => {
    setSelectedVendor(vendor);
    setSelectedTab('Weapon');
    setSelectedSubCategory(null);
    fetchVendorItems(vendor.id);
  };

  const fetchVendorItems = (vendorId: number) => {
    setLoadingItems(true);
    axios
      .get(`/api/market/vendors/${vendorId}/items`, { withCredentials: true })
      .then((response) => {
        setItems(response.data);
        setLoadingItems(false);
      })
      .catch((error) => {
        console.error('Error fetching vendor items:', error);
        displayToast('Failed to load items.', 'error');
        setLoadingItems(false);
      });
  };

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
    setSelectedSubCategory(null);
  };

  const handleSubCategorySelect = (subCategory: string) => {
    setSelectedSubCategory(subCategory);
  };

  const handleBuyItem = (item: VendorInventory) => {
    axios
      .post(
        '/api/market/buy',
        {
          gladiatorId,
          vendorId: selectedVendor?.id,
          itemId: item.itemId,
          itemType: item.itemCategory,
        },
        { withCredentials: true }
      )
      .then(() => {
        displayToast('Item bought successfully!', 'success');
        fetchVendorItems(selectedVendor!.id);
      })
      .catch((error) => {
        console.error('Error buying item:', error);
        displayToast('Failed to buy item.', 'error');
      });
  };

  const filteredItems = items
    .filter((item) => {
      const isCategoryMatch = item.itemCategory === selectedTab;
      const isSubCategoryMatch =
        !selectedSubCategory ||
        (selectedTab === 'Weapon' && item.itemDetails.weaponType === selectedSubCategory) ||
        (selectedTab === 'Armor' && item.itemDetails.armorType === selectedSubCategory) ||
        (selectedTab === 'Accessory' && item.itemDetails.accessoryType === selectedSubCategory);

      return isCategoryMatch && isSubCategoryMatch;
    })
    .sort((a, b) => {
      const levelA = a.itemDetails.requiredLevel || 0;
      const levelB = b.itemDetails.requiredLevel || 0;

      if (levelA !== levelB) return levelA - levelB;
      if (a.price !== b.price) return a.price - b.price;
      return a.itemDetails.name.localeCompare(b.itemDetails.name);
    });

  const renderSubCategoryButtons = () => {
    const subCategories: Record<string, string[]> = {
      Weapon: ['Axe', 'Mace', 'Sword', 'Staff', 'Dagger', 'Chain'],
      Armor: ['Head', 'Shoulders', 'Torso', 'Hands', 'Legs', 'Feet'],
      Accessory: ['Cloak', 'Neck', 'Ring', 'Armring', 'Amulet'],
    };

    if (subCategories[selectedTab]) {
      return (
        <div className={styles.subCategoryButtons}>
          {subCategories[selectedTab].map((subCategory) => (
            <button
              key={subCategory}
              onClick={() => handleSubCategorySelect(subCategory)}
              className={`${styles.subCategoryButton} ${
                selectedSubCategory === subCategory ? styles.activeSubCategory : ''
              }`}
            >
              {subCategory}
            </button>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderTableHeaders = () => {
    switch (selectedTab) {
      case 'Weapon':
        return (
          <>
            <th>Name</th>
            <th>Damage</th>
            <th>Slot</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Armor':
        return (
          <>
            <th>Name</th>
            <th>Absorption</th>
            <th>Armor Type</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Shield':
        return (
          <>
            <th>Name</th>
            <th>Block Value</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Accessory':
        return (
          <>
            <th>Name</th>
            <th>Level Req.</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Potion':
        return (
          <>
            <th>Name</th>
            <th>Effect</th>
            <th>Duration</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      case 'Knowledge':
        return (
          <>
            <th>Name</th>
            <th>Type</th>
            <th>Effect</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Action</th>
          </>
        );
      default:
        return null;
    }
  };

  const renderTableRows = () => {
    return filteredItems.map((item) => (
      <tr key={item.id}>
        <td>
          <Tooltip itemId={item.itemDetails.id}>
            <span>{item.itemDetails.name}</span>
          </Tooltip>
        </td>
        {selectedTab === 'Weapon' && (
          <>
            <td>{item.itemDetails.minDamage} - {item.itemDetails.maxDamage}</td>
            <td>{item.itemDetails.slot === 'oneHand' ? 'One-Handed' : 'Two-Handed'}</td>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className={styles.buyButton}>Buy</button></td>
          </>
        )}
        {selectedTab === 'Armor' && (
          <>
            <td>{item.itemDetails.absorption}</td>
            <td>{item.itemDetails.armorType}</td>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className={styles.buyButton}>Buy</button></td>
          </>
        )}
        {selectedTab === 'Shield' && (
          <>
            <td>{item.itemDetails.blockValue}</td>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className={styles.buyButton}>Buy</button></td>
          </>
        )}
        {selectedTab === 'Accessory' && (
          <>
            <td>{item.itemDetails.requiredLevel}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className={styles.buyButton}>Buy</button></td>
          </>
        )}
        {selectedTab === 'Potion' && (
          <>
            <td>{item.itemDetails.potionEffect}</td>
            <td>{item.itemDetails.duration} seconds</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className={styles.buyButton}>Buy</button></td>
          </>
        )}
        {selectedTab === 'Knowledge' && (
          <>
            <td>{item.itemDetails.knowledgeType}</td>
            <td>{item.itemDetails.effectDescription}</td>
            <td>{item.price}</td>
            <td>{item.stock}</td>
            <td><button onClick={() => handleBuyItem(item)} className={styles.buyButton}>Buy</button></td>
          </>
        )}
      </tr>
    ));
  };

  if (loadingVendors) {
    return <div className={styles.loading}>Loading vendors...</div>;
  }

  if (!selectedVendor) {
    return (
      <div className={styles.marketContainer}>
        <h1 className={styles.marketTitle}>Marketplace</h1>
        <p className={styles.vendorSelection}>Choose a merchant to view their goods:</p>
        <div className={styles.vendorList}>
          {vendors.map((vendor) => (
            <div key={vendor.id} onClick={() => handleVendorSelect(vendor)} className={styles.vendorCard}>
              <h3 className={styles.vendorName}>{vendor.name}</h3>
              <p className={styles.vendorDescription}>{vendor.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.marketContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h1 className={styles.marketTitle}>{selectedVendor.name}</h1>
      <button onClick={() => setSelectedVendor(null)} className={styles.backButton}>
        Back to Marketplace
      </button>

      <div className={styles.itemsSection}>
        <div className={styles.tabs}>
          <button onClick={() => handleTabSelect('Weapon')} className={`${styles.tabButton} ${selectedTab === 'Weapon' ? styles.active : ''}`}>
            Weapons
          </button>
          <button onClick={() => handleTabSelect('Armor')} className={`${styles.tabButton} ${selectedTab === 'Armor' ? styles.active : ''}`}>
            Armor
          </button>
          <button onClick={() => handleTabSelect('Shield')} className={`${styles.tabButton} ${selectedTab === 'Shield' ? styles.active : ''}`}>
            Shields
          </button>
          <button onClick={() => handleTabSelect('Accessory')} className={`${styles.tabButton} ${selectedTab === 'Accessory' ? styles.active : ''}`}>
            Accessories
          </button>
          <button onClick={() => handleTabSelect('Potion')} className={`${styles.tabButton} ${selectedTab === 'Potion' ? styles.active : ''}`}>
            Potions
          </button>
          <button onClick={() => handleTabSelect('Knowledge')} className={`${styles.tabButton} ${selectedTab === 'Knowledge' ? styles.active : ''}`}>
            Knowledge
          </button>
        </div>

        {renderSubCategoryButtons()}

        {loadingItems ? (
          <div className={styles.loading}>Loading items...</div>
        ) : filteredItems.length > 0 ? (
          <div className={styles.itemsTableContainer}>
            <table className={styles.itemsTable}>
              <thead>
                <tr>{renderTableHeaders()}</tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        ) : (
          <p className={styles.noItemsMessage}>
            No items available for {selectedTab} {selectedSubCategory && ` - ${selectedSubCategory}`}
          </p>
        )}
      </div>
    </div>
  );
};

export default Marketplace;
