import React, { useState } from 'react';
import styles from './ChatBox.module.css';

interface ChatMessage {
  userName: string;
  message: string;
}

const ChatBox: React.FC<{ chatMessages: ChatMessage[]; lobbyId: string; socket: any }> = ({ chatMessages, lobbyId, socket }) => {
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      socket.emit('chat_message_lobby', { lobbyId, message: newMessage });
      setNewMessage('');
    }
  };

  return (
    <div className={styles.chatBox}>
      <div className={styles.messages}>
        {chatMessages.map((msg, index) => (
          <p key={index}>
            <strong>{msg.userName}:</strong> {msg.message}
          </p>
        ))}
      </div>
      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default ChatBox;
