import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useGladiator } from '../../../contexts/GladiatorContext';
import './ChapterView.css';

interface Chapter {
  text: string;
  is_end: boolean;
}

interface Choice {
  id: number;
  text: string;
  next_chapter_id: number;
}

const ChapterView: React.FC = () => {
  const { adventureId, chapterId } = useParams<{ adventureId: string; chapterId: string }>();
  const [chapter, setChapter] = useState<Chapter | null>(null);
  const [choices, setChoices] = useState<Choice[]>([]);
  const [completed, setCompleted] = useState(false); // New state to track adventure completion
  const { gladiator } = useGladiator();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChapterData = async () => {
      try {
        const chapterResponse = await axios.get(`/api/adventures/${adventureId}/chapter/${chapterId}`);
        setChapter(chapterResponse.data.chapter);
        setChoices(chapterResponse.data.choices);
      } catch (error) {
        console.error('Error fetching chapter data:', error);
      }
    };

    fetchChapterData();
  }, [adventureId, chapterId]);

  const handleChoice = async (choiceId: number) => {
    try {
      if (gladiator) {
        const response = await axios.post(`/api/adventures/${gladiator.id}/choice`, { choiceId });
        const { nextChapter, message, rewards } = response.data;

        if (message === 'Adventure completed!') {
          console.log('Adventure rewards:', rewards);
          setCompleted(true); // Set adventure as completed
        } else {
          navigate(`/adventure/${adventureId}/chapter/${nextChapter.id}`);
        }
      } else {
        console.error('No gladiator found. Please select a gladiator to continue the adventure.');
      }
    } catch (error) {
      console.error('Error handling choice:', error);
    }
  };

  if (!chapter) {
    return <p>Loading...</p>;
  }

  return (
    <div className="chapter-view">
      <p className="chapter-text">{chapter.text}</p>
      {completed ? (
        <p className="end-message">The adventure has concluded! You return to Njordheim..</p>
      ) : (
        <>
          {chapter.is_end ? (
            <p className="end-message">The adventure has concluded.</p>
          ) : (
            <ul className="choices">
              {choices.map((choice) => (
                <li key={choice.id} onClick={() => handleChoice(choice.id)} className="choice-item">
                  {choice.text}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default ChapterView;
