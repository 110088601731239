// GladiatorBar.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useSocket } from '../../contexts/SocketContext';
import { useGladiator } from '../../contexts/GladiatorContext';
import styles from './GladiatorBar.module.css';
import battleNotificationSound from '../../sounds/battle_notification.mp3';

interface GladiatorStats {
  name?: string;
  race?: string;
  sex?: string;
  avatar?: number;
  level?: number;
  health?: number;
  maxHealth?: number;
  experience?: number;
  nextExperience?: number;
  previousExperience?: number;
  rounds?: number;
  maxRounds?: number;
  form?: number;
  silver?: number;
  reputation?: number;
  primarySkillPoints?: number;
  secondarySkillPoints?: number;
}

const GladiatorBar: React.FC = () => {
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { gladiator, setGladiator } = useGladiator();
  const [gladiatorStats, setGladiatorStats] = useState<GladiatorStats | null>(gladiator);

  const [battleNotification, setBattleNotification] = useState<{ battleId: number } | null>(null);
  const notificationAudioRef = useRef(new Audio(battleNotificationSound));
  const [levelUpNotification, setLevelUpNotification] = useState(false);
  const [regenTimerProgress, setRegenTimerProgress] = useState<number>(0);

  const [pendingFriendRequests, setPendingFriendRequests] = useState<number>(0);
  const [unreadMails, setUnreadMails] = useState<number>(0);

  useEffect(() => {
    if (socket) {
      socket.on('update_gladiator_stats', (data: { gladiatorId: number; updatedStats: Partial<GladiatorStats> }) => {
        const { gladiatorId, updatedStats } = data;
        const activeGladiatorId = parseInt(localStorage.getItem('gladiatorId') || '', 10);
    
        if (gladiatorId === activeGladiatorId) {
            setGladiatorStats((prevState: GladiatorStats | null) => ({
                ...prevState,
                ...updatedStats,
            }));
        }
    });

      socket.on('battle_notification', (battleId: number) => {
        setBattleNotification({ battleId });
        notificationAudioRef.current.play().catch((error) => {
          console.error('Failed to play sound:', error);
        });

        setTimeout(() => {
          setBattleNotification(null);
        }, 30000);
      });

      socket.on('level_up_notification', () => {
        setLevelUpNotification(true);
      });

      socket.on('gladiator_regen', (data: { gladiatorId: number; regen: { hp: number; rounds: number } }) => {
        const { gladiatorId, regen } = data;
        const activeGladiatorId = parseInt(localStorage.getItem('gladiatorId') || '', 10);
        if (gladiatorId === activeGladiatorId) {
            setGladiatorStats((prevState) => {
                if (!prevState) return prevState;
                return {
                    ...prevState,
                    health: regen.hp,
                    rounds: regen.rounds,
                };
            });
        }
      });

      socket.on('friendRequestHandled', () => {
        setPendingFriendRequests((prev) => Math.max(prev - 1, 0));
      });

      socket.on('newMail', () => {
        setUnreadMails(prev => prev + 1);
      });

      socket.on('mailRead', () => {
        setUnreadMails(prev => Math.max(prev - 1, 0));
      });

      return () => {
        socket.off('update_gladiator_stats');
        socket.off('battle_notification');
        socket.off('level_up_notification');
        socket.off('gladiator_regen');
        socket.off('newFriendRequest');
        socket.off('friendRequestHandled');
        socket.off('newMail');
        socket.off('mailRead');
      };
    }
  }, [socket]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const friendResponse = await axios.get('/api/friend/pending-count', { withCredentials: true });
        setPendingFriendRequests(friendResponse.data.count);

        const mailResponse = await axios.get('/api/mail/unread-count', { withCredentials: true });
        setUnreadMails(mailResponse.data.count);
      } catch (error) {
        console.error("Error fetching notification counts:", error);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (gladiator) {
      setGladiatorStats(gladiator);

      if (gladiator.primarySkillPoints > 0 || gladiator.secondarySkillPoints > 0) {
        setLevelUpNotification(true);
      } else {
        setLevelUpNotification(false);
      }
    }
  }, [gladiator]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRegenTimerProgress(Date.now() % 180000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (!gladiatorStats) {
    return <p>No gladiator found.</p>;
  }

  const healthPercentage = (gladiatorStats.health ?? 0) / (gladiatorStats.maxHealth ?? 1) * 100;
  const currentLevelExp = (gladiatorStats.experience ?? 0) - (gladiatorStats.previousExperience ?? 0);
  const levelExpNeeded = (gladiatorStats.nextExperience ?? 0) - (gladiatorStats.previousExperience ?? 0);
  const hasLeveledUp = currentLevelExp >= levelExpNeeded;
  const experiencePercentage = hasLeveledUp ? 100 : (currentLevelExp / levelExpNeeded) * 100;
  const experienceDisplay = hasLeveledUp ? "Leveled up!" : `${currentLevelExp} / ${levelExpNeeded} Exp`;
  const minutes = Math.floor((180000 - regenTimerProgress) / 60000);
  const seconds = Math.floor(((180000 - regenTimerProgress) % 60000) / 1000);

  const handleViewBattle = () => {
    if (battleNotification) {
      navigate(`/battle-result/${battleNotification.battleId}`);
    }
    setBattleNotification(null);
  };

  const handleDismissNotification = () => {
    setBattleNotification(null);
  };

  const handleLevelUp = () => {
    navigate('/level-up');
    setLevelUpNotification(false);
  };

  return (
    <div className={styles.gladiatorBar}>
      <div className={styles.contentContainer}>
        <div className={styles.gladiatorAvatar}>
          <img
            src={`/img/avatars/${gladiatorStats.race?.toLowerCase() ?? 'default'}.${gladiatorStats.sex?.toLowerCase() ?? 'default'}.${gladiatorStats.avatar ?? 1}.png`}
            alt={gladiatorStats.name ?? 'Unknown Gladiator'}
          />
          <h2>{gladiatorStats.name ?? 'Unknown Gladiator'}</h2>
          <p>Level: {gladiatorStats.level ?? 1}</p>

          {battleNotification && (
            <div className={styles.battleNotification}>
              <p>I just participated in a battle, want to see it?</p>
              <button onClick={handleViewBattle}>View Battle</button>
              <button onClick={handleDismissNotification}>Close</button>
            </div>
          )}
        </div>

        <div className={styles.gladiatorStats}>
          <div className={styles.stat}>
            <div className={`${styles.progressBar} ${styles.hpBar}`}>
              <div className={styles.fill} style={{ width: `${healthPercentage}%` }} />
              <span>{Math.floor(gladiatorStats.health ?? 0)} / {Math.floor(gladiatorStats.maxHealth ?? 1)} HP</span>
            </div>
          </div>
          <div className={styles.stat}>
            <div className={`${styles.progressBar} ${styles.epBar}`}>
              <div className={styles.fill} style={{ width: `${experiencePercentage}%` }} />
              <span>{experienceDisplay}</span>
            </div>
          </div>
          <div className={styles.stat}>
            <div className={`${styles.progressBar} ${styles.regenBar}`}>
              <div className={styles.fill} style={{ width: `${(regenTimerProgress / 180000) * 100}%` }} />
              <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds} Regen</span>
            </div>
          </div>
          <div className={styles.gladiatorForm}>
            <div className={styles.labelValuePair}>
              <label>Form:</label>
              <span>{gladiatorStats.form}%</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Rounds:</label>
              <span>{Math.floor(gladiatorStats.rounds ?? 0)} / {gladiatorStats.maxRounds ?? 0}</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Silver:</label>
              <span>{gladiatorStats.silver}</span>
            </div>
            <div className={styles.labelValuePair}>
              <label>Reputation:</label>
              <span>{gladiatorStats.reputation}</span>
            </div>
          </div>
        </div>

        {levelUpNotification && (
          <div className={styles.levelUpNotification}>
            <button onClick={handleLevelUp} className={styles.levelUpButton}>Level Up!</button>
          </div>
        )}

        {/* Spacer to push links down */}
        <div className={styles.spacer} />

        <div className={styles.gladiatorLinks}>
          <h3>Gladiator Links</h3>
          <ul>
            <li><Link to="/gladiator">Profile</Link></li>
            <li><Link to="/battle-history">Battle History</Link></li>
            <li><Link to="/inventory">Inventory</Link></li>
            {/* <li><Link to="/bio">Bio</Link></li>
            <li><Link to="/statistics">Statistics</Link></li>
            <li><Link to="/attributes">Attributes</Link></li>
            <li><Link to="/profession">Profession</Link></li>
            <li><Link to="/achievements">Achievements</Link></li>
            <li><Link to="/activity">Activity</Link></li> */}
          </ul>
        </div>

        <div className={styles.accountLinks}>
          <h3>Account Links</h3>
          <ul>
            <li><Link to="/gladiators">My Gladiators</Link></li>
            {/* <li><Link to="/notes">Notes</Link></li> */}
            <li>
            <Link to="/friends">Friends List</Link>
              {pendingFriendRequests > 0 && (
                <span className={styles.notificationBadge}>{pendingFriendRequests}</span>
              )}
            <li>
              <Link to="/mail">Mail</Link>
              {unreadMails > 0 && (
                <span className={styles.notificationBadge}>{unreadMails}</span>
              )}
            </li>
            </li>
            <li><Link to="/account">Account</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GladiatorBar;
