import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import Toast from '../Toast';
import styles from './Mail.module.css';

interface MailItem {
  id: number;
  senderId: number | null;
  senderName: string;
  subject: string;
  message: string;
  isRead: boolean;
  isAdmin: boolean;
  createdAt: string;
}

interface Friend {
  id: number;
  name: string;
  lastSeen: string | null;
  gladiator: {
    id: number;
    name: string;
    level: number;
    reputation: number;
    avatar: number;
    race: string;
    sex: string;
  };
}

interface SearchResult {
  id: number;
  name: string;
  type: 'user' | 'gladiator';
}

interface User extends SearchResult {
  username: string;
  displayName?: string;
}

interface Gladiator extends SearchResult {
  level: number;
  race: string;
  sex: string;
  avatar: number;
}

const Mail: React.FC = () => {
  const [inbox, setInbox] = useState<MailItem[]>([]);
  const [selectedMail, setSelectedMail] = useState<MailItem | null>(null);
  const [replyMessage, setReplyMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchUsername, setSearchUsername] = useState<string>('');
  const [newMessage, setNewMessage] = useState<string>('');
  const [friends, setFriends] = useState<Friend[]>([]);
  const [showFriendsOverlay, setShowFriendsOverlay] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const mailsPerPage = 20;

  useEffect(() => {
    fetchInbox(currentPage);
  }, [currentPage]);

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => setToastMessage(null), 3000);
  };

  const fetchInbox = async (page: number) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/mail/inbox', {
        params: { page, limit: mailsPerPage },
        withCredentials: true,
      });
      setInbox(response.data.inbox);
      setTotalPages(Math.max(1, Math.ceil(response.data.totalCount / mailsPerPage)));
    } catch (error) {
      displayToast('Error fetching inbox', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectMail = (mail: MailItem) => {
    setSelectedMail(mail);
    setReplyMessage('');
    if (!mail.isRead) {
      axios.post('/api/mail/mark-read', { mailId: mail.id }, { withCredentials: true })
        .then(() => {
          setInbox(prevInbox =>
            prevInbox.map(m =>
              m.id === mail.id ? { ...m, isRead: true } : m
            )
          );
        })
        .catch(error => displayToast('Error marking mail as read', 'error'));
    }
  };

  const handleReply = async () => {
    if (selectedMail && replyMessage.trim()) {
      try {
        await axios.post(
          '/api/mail/reply',
          {
            mailId: selectedMail.id,
            senderId: selectedMail.senderId,
            message: replyMessage,
          },
          { withCredentials: true }
        );
        setReplyMessage('');
        displayToast('Reply sent successfully', 'success');
      } catch (error) {
        displayToast('Failed to send reply', 'error');
      }
    }
  };

  const handleSendMessage = async () => {
    if (searchUsername.trim() && newMessage.trim()) {
      try {
        await axios.post(
          '/api/mail/send',
          {
            recipientName: searchUsername,
            subject: 'New Message',
            message: newMessage,
          },
          { withCredentials: true }
        );
        displayToast('Message sent successfully', 'success');
        setSearchUsername('');
        setNewMessage('');
        setSearchResults([]);
      } catch (error) {
        displayToast('Failed to send message', 'error');
      }
    }
  };

  const fetchFriends = async () => {
    try {
      const response = await axios.get('/api/friend/list', { withCredentials: true });
      setFriends(response.data.friends);
    } catch (error) {
      displayToast('Error fetching friends', 'error');
    }
  };

  const searchUsernames = async (username: string) => {
    try {
      const response = await axios.get(`/api/mail/search?username=${username}`, { withCredentials: true });
      const users = response.data.users.map((user: User) => ({ ...user, type: 'user' }));
      const gladiators = response.data.gladiators.map((gladiator: Gladiator) => ({
        ...gladiator,
        type: 'gladiator',
        name: gladiator.name,
      }));
      
      setSearchResults([...users, ...gladiators]);
    } catch (error) {
      displayToast('Error searching for usernames or gladiators', 'error');
    }
  };

  const handleDeleteMail = async (mailId: number) => {
    try {
      await axios.post('/api/mail/delete', { mailId }, { withCredentials: true });
      setInbox(prevInbox => prevInbox.filter(mail => mail.id !== mailId));
      displayToast('Mail deleted successfully', 'success');
    } catch (error) {
      displayToast('Failed to delete mail', 'error');
    }
  };

  const openFriendsOverlay = () => {
    fetchFriends();
    setShowFriendsOverlay(true);
  };

  const selectRecipientFromFriendList = (username: string) => {
    setSearchUsername(username);
    setShowFriendsOverlay(false);
  };

  const selectRecipientFromSearch = (username: string) => {
    setSearchUsername(username);
    setSearchResults([]);
  };

  const goToPage = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.mailContainer}>
      <h1>Inbox</h1>

      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className={styles.inboxContainer}>
          <div className={styles.inboxList}>
            <h2>Messages</h2>
            {inbox.length > 0 ? (
              <ul>
                {inbox.map((mail) => (
                  <li
                    key={mail.id}
                    onClick={() => handleSelectMail(mail)}
                    className={`${styles.messageContainer} ${mail.isRead ? styles.read : styles.unread}`}
                  >
                    <strong>From:</strong> {mail.isAdmin ? 'Admin' : mail.senderName} <br />
                    <strong>Subject:</strong> {mail.subject} <br />
                    <span className={styles.date}>{new Date(mail.createdAt).toLocaleString()}</span>
                    <button onClick={() => handleDeleteMail(mail.id)} className={styles.deleteButton}>Delete</button>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No messages in your inbox.</p>
            )}
            <div className={styles.pagination}>
              <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
              <span>
                Page {currentPage} of {totalPages ?? 1}
              </span>
              <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
            </div>
          </div>

          <div className={styles.mailViewer}>
            {selectedMail ? (
              <>
                <div className={styles.messageContainer}>
                  <div className={styles.messageHeader}>
                    <h2>Message from {selectedMail.isAdmin ? 'Admin' : selectedMail.senderName}</h2>
                    <button
                      onClick={() => setSelectedMail(null)}
                      className={styles.closeButton}
                      title="Close"
                    >
                      ✖
                    </button>
                  </div>
                  <p><strong>Subject:</strong> {selectedMail.subject}</p>
                  <p dangerouslySetInnerHTML={{ __html: selectedMail.message }}></p>
                </div>
                <div className={styles.replySection}>
                  <h3>Reply to {selectedMail.senderName}</h3>
                  <textarea
                    placeholder="Write your reply..."
                    className={styles.replyTextarea}
                    value={replyMessage}
                    onChange={(e) => setReplyMessage(e.target.value)}
                    disabled={selectedMail.isAdmin}
                  />
                  <button onClick={handleReply} className={styles.sendButton} disabled={!replyMessage.trim() || selectedMail.isAdmin}>
                    Send Reply
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.sendMessageSection}>
                <h2>Send a New Message</h2>
                <div className={styles.recipientContainer}>
                  <FontAwesomeIcon
                    icon={faBook}
                    title="Open Friend List"
                    className={styles.bookIcon}
                    onClick={openFriendsOverlay}
                  />
                  <input
                    type="text"
                    placeholder="Search for username..."
                    className={styles.searchInput}
                    value={searchUsername}
                    onChange={(e) => {
                      setSearchUsername(e.target.value);
                      searchUsernames(e.target.value);
                    }}
                  />
                </div>
                {searchUsername.trim() && searchResults.length > 0 && (
                  <ul className={styles.searchDropdown}>
                    {searchResults.map(result => (
                      <li
                        key={result.id}
                        onClick={() => selectRecipientFromSearch(result.name)}
                        className={styles.searchResultItem}
                      >
                        {result.type === 'user' ? (
                          <span>
                            <strong>User:</strong> {result.username}
                          </span>
                        ) : (
                          <span>
                            <strong>Gladiator:</strong> {result.name} {result.type === 'gladiator'}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                <textarea
                  placeholder="Write your message..."
                  className={styles.replyTextarea}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <button onClick={handleSendMessage} className={styles.sendButton} disabled={!searchUsername.trim() || !newMessage.trim()}>
                  Send Message
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {showFriendsOverlay && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <h2>Select a Friend</h2>
            <button onClick={() => setShowFriendsOverlay(false)} className={styles.closeOverlayButton}>Close</button>
            <ul>
              {friends.map(friend => (
                <li key={friend.id} onClick={() => selectRecipientFromFriendList(friend.name)}>
                  {friend.name} (Last seen: {friend.lastSeen ? new Date(friend.lastSeen).toLocaleString() : 'Offline'})
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mail;
