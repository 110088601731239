import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './ItemTooltip.css';
import { useGladiator } from '../../contexts/GladiatorContext';

interface TooltipProps {
  itemId: number;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ itemId, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [item, setItem] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { gladiator } = useGladiator();

  // Define default tooltip dimensions based on item category
  const getDefaultDimensions = (category: string | undefined) => {
    switch (category) {
      case 'Weapon':
        return { width: 300, height: 310 };
      case 'Shield':
        return { width: 300, height: 200 };
      case 'Armor':
      case 'Accessory':
        return { width: 300, height: 200 };
      case 'Potion':
        return { width: 300, height: 200 };
      case 'Material':
        return { width: 300, height: 140 };
      case 'Knowledge':
        return { width: 300, height: 250 };
      default:
        return { width: 300, height: 150 };
    }
  };

  const [tooltipDimensions, setTooltipDimensions] = useState(getDefaultDimensions(item?.itemCategory));

  const handleMouseOver = () => {
    setVisible(true);
  };

  const handleMouseOut = () => {
    setVisible(false);
    setItem(null);
    setLoading(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    let x = e.clientX + 15;
    let y = e.clientY + 15;

    if (x + tooltipDimensions.width > window.innerWidth) {
      x = e.clientX - tooltipDimensions.width - 15;
    }

    if (y + tooltipDimensions.height > window.innerHeight) {
      y = e.clientY - tooltipDimensions.height - 15;
    }

    setPosition({ x, y });
  };

  const fetchItemData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/item/${itemId}`);
      const fetchedItem = response.data;
      setItem(fetchedItem);
      setLoading(false);

      // Update tooltip dimensions based on item category and content
      const defaultDimensions = getDefaultDimensions(fetchedItem.itemCategory);
      const extraHeight = fetchedItem.description?.length > 100 ? 50 : 0;
      setTooltipDimensions({
        width: defaultDimensions.width,
        height: defaultDimensions.height + extraHeight,
      });
    } catch (error) {
      console.error("Failed to fetch item data:", error);
      setLoading(false);
    }
  }, [itemId]);

  useEffect(() => {
    if (visible && (!item || item.id !== itemId)) {
      const debounceFetch = setTimeout(fetchItemData, 10);
      return () => clearTimeout(debounceFetch);
    }
  }, [itemId, visible, item, fetchItemData]);

  const meetsRequirements = () => {
    return {
      level: item?.requiredLevel ? gladiator.level >= item.requiredLevel : true,
      race:
        !item?.itemRace ||
        item.itemRace === 'All' ||
        item.itemRace === gladiator.race ||
        (item.itemRace === 'Red Blood' && ['Elf', 'Dwarf', 'Human'].includes(gladiator.race)) ||
        (item.itemRace === 'Black Blood' && ['Orc', 'Goblin', 'Troll'].includes(gladiator.race)),
      title:
        !item?.itemTitle ||
        item.itemTitle === 'Normal' ||
        (item.itemTitle === 'Gladiator' && ['Gladiator', 'Legend', 'Dishonorable'].includes(gladiator.title)) ||
        (item.itemTitle === 'Legend' && gladiator.title === 'Legend') ||
        (item.itemTitle === 'Dishonorable' && gladiator.title === 'Dishonorable') ||
        (item.itemTitle === 'Reputable' && gladiator.reputation > 0) ||
        (item.itemTitle === 'Disreputable' && gladiator.reputation < 0),
    };
  };
  
  const renderItemDetails = () => {
    if (!item) return <p>Loading...</p>;

    const requirements = meetsRequirements();
    const formatSlot = (slot: string) => {
      if (slot === 'oneHand') return 'One-Handed';
      if (slot === 'twoHand') return 'Two-Handed';
      return slot;
    };

    const meetsWeaponSkillRecommendation = item.skillRecommendation ? gladiator.weaponSkills[item.weaponType?.toLowerCase() as keyof typeof gladiator.weaponSkills] >= item.skillRecommendation : true;
    const meetsShieldSkillRecommendation = item.skillRecommendation ? gladiator.weaponSkills['shield'] >= item.skillRecommendation : true;

    switch (item.itemCategory) {
      case 'Weapon':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>Name:</strong> {item.name}</p>
            <p><strong>Damage:</strong> {item.minDamage ?? 'N/A'} - {item.maxDamage ?? 'N/A'} ({item.dmgCap ?? 'N/A'})</p>
            <p><strong>Crit Chance:</strong> {item.critChance + '%'}</p>
            <p><strong>Crit Damage:</strong> {item.critDamage + '%'}</p>
            {item.weaponType === 'Staff' && (
              <>
                <p><strong>Momentum Chance:</strong> {item.momentumChance ? (item.momentumChance * 100).toFixed(0) + '%' : 'N/A'}</p>
                <p><strong>Momentum Multiplier:</strong> {item.momentumMultiplier ? (item.momentumMultiplier * 100).toFixed(0) + '%' : 'N/A'}</p>
              </>
            )}
            <p><strong>Durability:</strong> {item.breakValue ?? 'N/A'}</p>
            <p><strong>Slot:</strong> {formatSlot(item.slot)}</p>
            <p><strong>Off-Hand:</strong> {item.canEquipInOffHand ? 'Yes' : 'No'}</p>
            <p><strong>Actions:</strong> {item.actionsPerRound ?? 'N/A'}</p>
            {item.skillRecommendation && (
              <p>
                <strong>Skill Recommendation:</strong>{' '}
                <span style={{ color: meetsWeaponSkillRecommendation ? 'inherit' : 'red' }}>{item.skillRecommendation}</span>
              </p>
            )}
            <p><strong>Level:</strong> <span style={{ color: requirements.level ? 'inherit' : 'red' }}>{item.requiredLevel ?? 'N/A'}</span></p>
            <p><strong>Race:</strong> <span style={{ color: requirements.race ? 'inherit' : 'red' }}>{item.itemRace ?? 'N/A'}</span></p>
            <p><strong>Title:</strong> <span style={{ color: requirements.title ? 'inherit' : 'red' }}>{item.itemTitle ?? 'N/A'}</span></p>
            {item.bonuses 
              ? item.bonuses.split(',').map((bonus: string, index: number) => (
                  <p key={index}>{bonus.trim()}</p>
                ))
              : <p></p>
            }
          </>
        );
      case 'Shield':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>Name:</strong> {item.name}</p>
            <p><strong>Block Value:</strong> {item.blockValue ?? 'N/A'}</p>
            <p><strong>Durability:</strong> {item.breakValue ?? 'N/A'}</p>
            <p><strong>Actions:</strong> {item.actionsPerRound ?? 'N/A'}</p>
            {item.skillRecommendation && (
              <p>
                <strong>Skill Recommendation:</strong>{' '}
                <span style={{ color: meetsShieldSkillRecommendation ? 'inherit' : 'red' }}>{item.skillRecommendation}</span>
              </p>
            )}
            <p><strong>Level:</strong> <span style={{ color: requirements.level ? 'inherit' : 'red' }}>{item.requiredLevel ?? 'N/A'}</span></p>
            <p><strong>Race:</strong> <span style={{ color: requirements.race ? 'inherit' : 'red' }}>{item.itemRace ?? 'N/A'}</span></p>
            <p><strong>Title:</strong> <span style={{ color: requirements.title ? 'inherit' : 'red' }}>{item.itemTitle ?? 'N/A'}</span></p>
            {item.bonuses 
              ? item.bonuses.split(',').map((bonus: string, index: number) => (
                  <p key={index}>{bonus.trim()}</p>
                ))
              : <p></p>
            }
          </>
        );
      case 'Armor':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>Name:</strong> {item.name}</p>
            <p><strong>Armor Type:</strong> {item.armorType ?? 'N/A'}</p>
            <p><strong>Level:</strong> <span style={{ color: requirements.level ? 'inherit' : 'red' }}>{item.requiredLevel ?? 'N/A'}</span></p>
            <p><strong>Race:</strong> <span style={{ color: requirements.race ? 'inherit' : 'red' }}>{item.itemRace ?? 'N/A'}</span></p>
            <p><strong>Title:</strong> <span style={{ color: requirements.title ? 'inherit' : 'red' }}>{item.itemTitle ?? 'N/A'}</span></p>
            {item.absorption ? (
              <p><strong>Absorption:</strong> {item.absorption}</p>
                ) : item.absorptionPercentage ? (
                  <p>
                    <strong>Absorption:</strong> {(item.absorptionPercentage * 100).toFixed(0)}%
                    {item.minAbsorption !== null && item.maxAbsorption !== null && (
                      <> (Min: {item.minAbsorption}, Max: {item.maxAbsorption})</>
                    )}
                  </p>
                ) : (
              <p><strong>Absorption:</strong> N/A</p>
            )}
            {item.bonuses 
              ? item.bonuses.split(',').map((bonus: string, index: number) => (
                  <p key={index}>{bonus.trim()}</p>
                ))
              : <p></p>
            }
          </>
        );
      case 'Accessory':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>Name:</strong> {item.name}</p>
            <p><strong>Type:</strong> {item.accessoryType ?? 'N/A'}</p>
            <p><strong>Level:</strong> <span style={{ color: requirements.level ? 'inherit' : 'red' }}>{item.requiredLevel ?? 'N/A'}</span></p>
            <p><strong>Race:</strong> <span style={{ color: requirements.race ? 'inherit' : 'red' }}>{item.itemRace ?? 'N/A'}</span></p>
            <p><strong>Title:</strong> <span style={{ color: requirements.title ? 'inherit' : 'red' }}>{item.itemTitle ?? 'N/A'}</span></p>
            {/* <p><strong>Bonuses:</strong></p> */}
            {item.bonuses 
              ? item.bonuses.split(',').map((bonus: string, index: number) => (
                  <p key={index}>{bonus.trim()}</p>
                ))
              : <p></p>
            }
          </>
        );
      case 'Potion':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>Name:</strong> {item.name}</p>
            <p><strong>Potion Type:</strong> {item.potionType ?? 'N/A'}</p>
            <p><strong>Level:</strong> <span style={{ color: requirements.level ? 'inherit' : 'red' }}>{item.requiredLevel ?? 'N/A'}</span></p>
            <p><strong>Race:</strong> <span style={{ color: requirements.race ? 'inherit' : 'red' }}>{item.itemRace ?? 'N/A'}</span></p>
            <p><strong>Title:</strong> <span style={{ color: requirements.title ? 'inherit' : 'red' }}>{item.itemTitle ?? 'N/A'}</span></p>
            <p><strong>Duration:</strong> {item.duration ?? 'N/A'} minutes</p>
            <p><strong>Effect:</strong> {item.potionEffect ?? 'N/A'}</p>
          </>
        );
      case 'Material':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>Name:</strong> {item.name}</p>
            <p><strong>Material Type:</strong> {item.materialType ?? 'N/A'}</p>
            <p><strong>Value:</strong> {item.value ?? 'N/A'}</p>
          </>
        );
      case 'Knowledge':
        return (
          <>
            <img src={`/img/items/${item.name}.png`} alt={item.name} style={{ width: '100px', height: '100px', float: 'right', marginLeft: '10px' }} onError={(e) => {const target = e.target as HTMLImageElement; target.onerror = null; target.src = '/img/items/no_img.png';}}/>
            <p><strong>{item.name}</strong></p>
            <p>{item.description}</p>
            <p><strong>Type:</strong> {item.knowledgeType ?? 'N/A'}</p>
            <p><strong>Level:</strong> <span style={{ color: requirements.level ? 'inherit' : 'red' }}>{item.requiredLevel ?? 'N/A'}</span></p>
            <p><strong>Race:</strong> <span style={{ color: requirements.race ? 'inherit' : 'red' }}>{item.itemRace ?? 'N/A'}</span></p>
            <p><strong>Title:</strong> <span style={{ color: requirements.title ? 'inherit' : 'red' }}>{item.itemTitle ?? 'N/A'}</span></p>
            {/* <p><strong>Learning Points:</strong> {item.learningPoints ?? 'N/A'}</p> */}
          </>
        );
      default:
        return <p>No details available for this item category.</p>;
    }
  };

  return (
    <>
      <div onMouseOver={handleMouseOver} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut} style={{ display: 'inline-block' }}>
        {children}
      </div>
      {visible && (
        <div
          className="tooltip"
          style={{
            top: position.y,
            left: position.x,
            position: 'fixed',
            zIndex: 9999,
            width: tooltipDimensions.width,
            // height: tooltipDimensions.height,
          }}
        >
          <div className="tooltip-body">
            {loading ? <p>Loading...</p> : renderItemDetails()}
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
