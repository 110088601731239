import React from 'react';
import styles from './Preferences.module.css';

interface PreferencesProps {
  preferences: {
    surrenderAt: number;
    strategy: string;
  };
  onUpdate: (updatedPreferences: { surrenderAt: number; strategy: string }) => void;
}

const Preferences: React.FC<PreferencesProps> = ({ preferences, onUpdate }) => {
  const handleUpdate = (field: string, value: number | string) => {
    onUpdate({ ...preferences, [field]: value });
  };

  return (
    <div className={styles.preferences}>
      <label>
        Surrender At:
        <input
          type="number"
          value={preferences.surrenderAt}
          onChange={(e) => handleUpdate('surrenderAt', parseInt(e.target.value))}
        />
      </label>
      <label>
        Strategy:
        <select value={preferences.strategy} onChange={(e) => handleUpdate('strategy', e.target.value)}>
          <option value="Normal">Normal</option>
          <option value="Offensive">Offensive</option>
          <option value="Defensive">Defensive</option>
        </select>
      </label>
    </div>
  );
};

export default Preferences;
