import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGladiator } from '../../../contexts/GladiatorContext';
import './History.css';

interface HistoryRecord {
    id: number;
    itemName: string;
    price: number;
    quantity: number;
    status: 'expired' | 'canceled' | 'fulfilled';
    createdAt: string;
    updatedAt: string;
    type: 'buy' | 'sell';
    role: 'buyer' | 'seller';
}

const History: React.FC = () => {
    const { gladiator } = useGladiator();
    const [history, setHistory] = useState<HistoryRecord[]>([]);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const limit = 20;

    useEffect(() => {
        if (gladiator?.id) {
            axios.get(`/api/auction/history/${gladiator.id}?page=${page}&limit=${limit}`)
                .then(response => {
                    const { history: orderHistory, totalRecords } = response.data;
                    const formattedHistory = orderHistory.map((order: any) => ({
                        id: order.id,
                        itemName: order.Item.name,
                        price: order.price,
                        quantity: order.quantity,
                        status: order.status,
                        updatedAt: order.updatedAt,
                        type: order.order_type,
                        role: order.role
                    }));
                    setHistory(formattedHistory);
                    setTotalRecords(totalRecords);
                })
                .catch(error => console.error('Error fetching history:', error));
        }
    }, [gladiator, page]);

    const handleNextPage = () => {
        if (page * limit < totalRecords) setPage(page + 1);
    };

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'expired': return 'expired-tint';
            case 'canceled': return 'canceled-tint';
            case 'fulfilled': return 'fulfilled-tint';
            default: return '';
        }
    };

    const getTypeStyle = (type: 'buy' | 'sell') => type === 'buy' ? 'buy-tint' : 'sell-tint';

    const formatStatus = (role: 'buyer' | 'seller', status: string) => {
        const orderTypeLabel = role === 'buyer' ? 'Purchase' : 'Sale';
        return `${orderTypeLabel} - ${status.charAt(0).toUpperCase() + status.slice(1)}`;
    };

    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('sv-SE');
        const formattedTime = date.toLocaleTimeString('en-GB', { hour12: false });
        return `${formattedDate} - ${formattedTime}`;
    };

    return (
        <div className="history">
            <h2>Auction History</h2>
            <div className="history-list-container">
                <div className="history-list">
                    {history.map(record => (
                        <div key={record.id} className={`history-record ${getStatusColor(record.status)} ${getTypeStyle(record.type)}`}>
                            <span className="item-name">{record.itemName}</span>
                            <span className="quantity">Qty: {record.quantity}</span>
                            <span className="price">Price: {record.price}</span>
                            <span className="status">{formatStatus(record.role, record.status)}</span>
                            <span className="date">{formatDateTime(record.updatedAt)}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>
                <span>Page {page}</span>
                <button onClick={handleNextPage} disabled={page * limit >= totalRecords}>Next</button>
            </div>
        </div>
    );
};

export default History;
