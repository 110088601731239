import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useGladiator } from '../../../contexts/GladiatorContext';
import styles from './TeamBattles.module.css';

interface Lobby {
  id: number;
  name: string;
  minLevel: number;
  maxLevel: number;
  maxPlayers: number;
  randomTeams: boolean;
  currentParticipants: number;
}

const TeamBattles: React.FC = () => {
  const [lobbies, setLobbies] = useState<Lobby[]>([]);
  const [filteredLobbies, setFilteredLobbies] = useState<Lobby[]>([]);
  const [inLobby, setInLobby] = useState<boolean>(false);
  const [showWithinLevel, setShowWithinLevel] = useState<boolean>(false);
  const [hideFullLobbies, setHideFullLobbies] = useState<boolean>(false);
  const navigate = useNavigate();
  const { gladiator } = useGladiator();

  useEffect(() => {
    const fetchLobbies = async () => {
      try {
        const response = await axios.get('/api/team-battle/lobbies');
        setLobbies(response.data);
        setFilteredLobbies(response.data);
        
        const participationResponse = await axios.get('/api/team-battle/participation', { withCredentials: true });
        setInLobby(participationResponse.data.inLobby);
      } catch (error) {
        console.error('Error fetching lobbies:', error);
      }
    };
    fetchLobbies();
  }, []);

  useEffect(() => {
    filterLobbies();
  }, [showWithinLevel, hideFullLobbies]);

  const filterLobbies = () => {
    let filtered = lobbies;

    if (showWithinLevel && gladiator) {
      filtered = filtered.filter(lobby =>
        gladiator.level >= lobby.minLevel && gladiator.level <= lobby.maxLevel
      );
    }

    if (hideFullLobbies) {
      filtered = filtered.filter(lobby => lobby.currentParticipants < lobby.maxPlayers);
    }

    setFilteredLobbies(filtered);
  };

  const handleCreateLobby = () => navigate('/team-battle/create');

  const handleJoinLobby = (lobbyId: number) => navigate(`/team-battle/lobby/${lobbyId}`);

  return (
    <div className={styles.teamBattlesContainer}>
      <h1 className={styles.title}>Team Battles</h1>
      {!inLobby && (
        <button className={styles.createLobbyButton} onClick={handleCreateLobby}>
          Create Lobby
        </button>
      )}

      <div className={styles.filterOptions}>
        <label>
          <input
            type="checkbox"
            checked={showWithinLevel}
            onChange={(e) => setShowWithinLevel(e.target.checked)}
          />
          Show Lobbies Within Level
        </label>
        <label>
          <input
            type="checkbox"
            checked={hideFullLobbies}
            onChange={(e) => setHideFullLobbies(e.target.checked)}
          />
          Hide Full Lobbies
        </label>
      </div>

      <div className={styles.lobbiesList}>
        {filteredLobbies.length === 0 ? (
          <p className={styles.noLobbies}>No lobbies available. Create one!</p>
        ) : (
          filteredLobbies.map((lobby) => (
            <div key={lobby.id} className={styles.lobbyCard} onClick={() => handleJoinLobby(lobby.id)}>
              <h2 className={styles.lobbyName}>{lobby.name}</h2>
              <p className={styles.lobbyLevel}>Levels: {lobby.minLevel} - {lobby.maxLevel}</p>
              <p className={styles.lobbyParticipants}>
                Participants: {lobby.currentParticipants} / {lobby.maxPlayers}
              </p>
              <p className={`${styles.lobbyTeams} ${lobby.randomTeams ? styles.random : ''}`}>
                Teams: {lobby.randomTeams ? 'Random' : 'Manual'}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TeamBattles;
