import React from 'react';
import styles from './TeamList.module.css';

interface TeamMember {
  id: string;
  name: string;
  level: number;
  userId: string;
}

interface TeamListProps {
  lobby: {
    teamOne: TeamMember[] | undefined;
    teamTwo: TeamMember[] | undefined;
  };
  userId: string;
  isOwner: boolean;
  onJoinTeam: (team: string) => void;
}

const TeamList: React.FC<TeamListProps> = ({ lobby, userId, isOwner, onJoinTeam }) => {
  if (!lobby || !lobby.teamOne || !lobby.teamTwo) {
    return <p>Loading teams...</p>; // Render a loading state if teams are undefined
  }

  return (
    <div className={styles.teams}>
      <div className={styles.team}>
        <h3>Team 1</h3>
        <ul>
          {lobby.teamOne.map((member) => (
            <li key={member.id}>
              {member.name} (Level {member.level})
              {isOwner && member.userId !== userId && <button onClick={() => console.log('Kick', member.id)}>Kick</button>}
            </li>
          ))}
        </ul>
        <button onClick={() => onJoinTeam('teamOne')}>Join Team 1</button>
      </div>
      <div className={styles.team}>
        <h3>Team 2</h3>
        <ul>
          {lobby.teamTwo.map((member) => (
            <li key={member.id}>
              {member.name} (Level {member.level})
              {isOwner && member.userId !== userId && <button onClick={() => console.log('Kick', member.id)}>Kick</button>}
            </li>
          ))}
        </ul>
        <button onClick={() => onJoinTeam('teamTwo')}>Join Team 2</button>
      </div>
    </div>
  );
};

export default TeamList;
