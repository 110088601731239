import React, { createContext, useState, useEffect, useContext, ReactNode, useCallback } from 'react';
import axios from 'axios';

interface Gladiator {
  id: number;
  name: string;
  level: number;
  race: string;
  sex: string;
  avatar: number;
}

interface GladiatorContextProps {
  gladiators: any[];
  gladiator: any | null;
  gladiatorId: number | null;
  fetchUserGladiators: () => Promise<{ gladiators: Gladiator[]; canCreate: boolean, isPremiumOrHigher: boolean } | undefined>;
  switchGladiator: (gladiator: any) => void;
  createGladiator: (gladiatorData: any) => Promise<void>;
  deleteGladiator: (gladiatorId: number) => Promise<void>;
  setGladiator: React.Dispatch<React.SetStateAction<any | null>>;
  setGladiatorId: (id: number | null) => void;
}

const GladiatorContext = createContext<GladiatorContextProps | undefined>(undefined);

interface GladiatorProviderProps {
  children: ReactNode;
}

export const GladiatorProvider: React.FC<GladiatorProviderProps> = ({ children }) => {
  const [gladiators, setGladiators] = useState<any[]>([]);
  const [gladiator, setGladiator] = useState<any | null>(null);
  const [gladiatorId, setGladiatorId] = useState<number | null>(() => {
    const storedId = localStorage.getItem('gladiatorId');
    return storedId ? parseInt(storedId, 10) : null;
  });

  useEffect(() => {
    if (gladiatorId !== null) {
      localStorage.setItem('gladiatorId', gladiatorId.toString());
    } else {
      localStorage.removeItem('gladiatorId');
    }
  }, [gladiatorId]);

  const fetchUserGladiators = useCallback(async (): Promise<{ gladiators: Gladiator[]; canCreate: boolean; isPremiumOrHigher: boolean } | undefined> => {
    try {
      const response = await axios.get('/api/gladiator/all', { withCredentials: true });
      const data = response.data;
      setGladiators(data.gladiators);
      return {
        gladiators: data.gladiators,
        canCreate: data.canCreate,
        isPremiumOrHigher: data.isPremiumOrHigher,
      };
    } catch (error) {
      console.error('Failed to fetch gladiators:', error);
      return undefined;
    }
  }, []);

  const switchGladiator = async (selectedGladiator: Gladiator) => {
    try {
      setGladiatorId(selectedGladiator.id);
      const response = await axios.get(`/api/gladiator/${selectedGladiator.id}`, { withCredentials: true });
      const fullGladiatorData = response.data;
      setGladiator(fullGladiatorData);
    } catch (error) {
      console.error('Failed to fetch gladiator data:', error);
    }
  };

  const createGladiator = async (gladiatorData: any) => {
    try {
      const response = await axios.post('/api/gladiator', gladiatorData, { withCredentials: true });
      setGladiators([...gladiators, response.data]);
      switchGladiator(response.data);
    } catch (error) {
      console.error("Failed to create gladiator:", error);
    }
  };

  const deleteGladiator = async (gladiatorId: number) => {
    try {
      await axios.delete(`/api/gladiator/${gladiatorId}`, { withCredentials: true });
      setGladiators(gladiators.filter(g => g.id !== gladiatorId));
      if (gladiatorId === gladiatorId) {
        switchGladiator(gladiators[0]);
      }
    } catch (error) {
      console.error("Failed to delete gladiator:", error);
    }
  };

  return (
    <GladiatorContext.Provider value={{ gladiators, gladiator, gladiatorId, fetchUserGladiators, switchGladiator, createGladiator, deleteGladiator, setGladiator, setGladiatorId }}>
      {children}
    </GladiatorContext.Provider>
  );
};

export const useGladiator = () => {
  const context = useContext(GladiatorContext);
  if (!context) {
    throw new Error('useGladiator must be used within a GladiatorProvider');
  }
  return context;
};
