import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './EditItem.module.css';

const EditItem: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [itemData, setItemData] = useState<Record<string, any>>({});
  const [showAllFields, setShowAllFields] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  interface Item {
    id: number;
    name: string;
    description: string;
    img: string;
    weight: number;
    value: number;
    requiredLevel: number | null;
    itemRace: string | null;
    itemTitle: string | null;
    itemCategory: string | null;
    bonuses: string | null;
    public: boolean;
    [key: string]: any;
  }

  const handleSearch = async () => {
    try {
      const response = await axios.get(`/api/admin/items/search?term=${searchTerm}`, { withCredentials: true });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleSelectItem = async (itemId: number) => {
    try {
      const response = await axios.get(`/api/admin/items/${itemId}`, { withCredentials: true });
      setSelectedItem(response.data);
      setItemData(response.data);
      setMessage(null);
    } catch (error) {
      console.error('Error loading item:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleSave = async () => {
    if (!selectedItem) return;

    try {
      await axios.put(`/api/admin/items/${selectedItem.id}`, itemData, { withCredentials: true });
      setMessage('Item updated successfully!');
    } catch (error) {
      console.error('Error updating item:', error);
      setMessage('Failed to update item.');
    }
  };

  const renderTextField = (label: string, name: string, type: string = 'text') => (
    <div className={styles.formGroup}>
      <label>{label}:</label>
      <input
        type={type}
        name={name}
        value={itemData[name] ?? ''}
        onChange={handleInputChange}
      />
    </div>
  );

  const renderCheckbox = (label: string, name: string) => (
    <div className={styles.checkboxGroup}>
      <label>{label}:</label>
      <input
        type="checkbox"
        name={name}
        checked={itemData[name] || false}
        onChange={handleCheckboxChange}
      />
    </div>
  );

  const renderSelectField = (label: string, name: string, options: string[]) => (
    <div className={styles.formGroup}>
      <label>{label}:</label>
      <select name={name} value={itemData[name] ?? ''} onChange={handleInputChange}>
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const renderConditionalFields = () => {
    const { itemCategory } = itemData;

    if (itemCategory === 'Weapon') {
      return (
        <>
          {renderSelectField('Weapon Type', 'weaponType', ['Axe', 'Sword', 'Mace', 'Dagger', 'Staff', 'Chain'])}
          {renderTextField('Min Damage', 'minDamage', 'number')}
          {renderTextField('Max Damage', 'maxDamage', 'number')}
          {renderTextField('Damage Cap', 'dmgCap', 'number')}
          {renderTextField('Crit Chance', 'critChance', 'number')}
          {renderTextField('Crit Damage', 'critDamage', 'number')}
          {renderCheckbox('Can Equip in Off-Hand', 'canEquipInOffHand')}
          {renderTextField('Actions per Round', 'actionsPerRound', 'number')}
          {renderTextField('Momentum Chance', 'momentumChance', 'number')}
          {renderTextField('Momentum Multiplier', 'momentumMultiplier', 'number')}
          {renderTextField('Strength Requirement', 'strengthRequirement', 'number')}
        </>
      );
    } else if (itemCategory === 'Armor') {
      return (
        <>
          {renderSelectField('Armor Type', 'armorType', ['Head', 'Shoulder', 'Torso', 'Leg', 'Hand', 'Feet'])}
          {renderTextField('Absorption (flat value)', 'absorption', 'number')}
          {renderTextField('Absorption Percentage (%)', 'absorptionPercentage', 'number')}
          {renderTextField('Min Absorption', 'minAbsorption', 'number')}
          {renderTextField('Max Absorption', 'maxAbsorption', 'number')}
        </>
      );
    } else if (itemCategory === 'Shield') {
      return (
        <>
          {renderTextField('Block Value', 'blockValue', 'number')}
          {renderTextField('Break Value', 'breakValue', 'number')}
          {renderSelectField('Shield Type', 'shieldType', ['Small', 'Medium', 'Large'])}
        </>
      );
    } else if (itemCategory === 'Accessory') {
      return (
        <>
          {renderSelectField('Accessory Type', 'accessoryType', ['Amulet', 'Armring', 'Cloak', 'Neck', 'Ring'])}
        </>
      );
    } else if (itemCategory === 'Material') {
      return renderTextField('Material Type', 'materialType');
    } else if (itemCategory === 'Potion') {
      return (
        <>
          {renderTextField('Potion Type', 'potionType')}
          {renderTextField('Potion Effect', 'potionEffect')}
          {renderTextField('Potion Duration', 'duration', 'number')}
        </>
      );
    } else if (itemCategory === 'Enchant') {
      return renderTextField('Enchant Modifiers', 'enchantModifiers');
    } else if (itemCategory === 'Knowledge') {
      return (
        <>
          {renderSelectField('Knowledge Type', 'knowledgeType', ['Tome', 'Scroll', 'Book'])}
          {renderTextField('Effect Description', 'effectDescription')}
        </>
      );
    }
    return null;
  };

  const renderAllFields = () => (
    <>
      {renderTextField('Name', 'name')}
      {renderTextField('Description', 'description')}
      {renderTextField('Image URL', 'img')}
      {renderTextField('Weight', 'weight', 'number')}
      {renderTextField('Value', 'value', 'number')}
      {renderTextField('Required Level', 'requiredLevel', 'number')}
      {renderSelectField('Item Race', 'itemRace', ['All', 'Red Blood', 'Elf', 'Dwarf', 'Human', 'Black Blood', 'Goblin', 'Orc', 'Troll'])}
      {renderSelectField('Item Title', 'itemTitle', ['Normal', 'Gladiator', 'Legend', 'Dishonorable', 'Reputable', 'Disreputable'])}
      {renderSelectField('Item Category', 'itemCategory', ['Weapon', 'Armor', 'Shield', 'Accessory', 'Material', 'Potion', 'Enchant', 'Knowledge'])}
      {renderSelectField('Slot', 'slot', ['oneHand', 'twoHand', 'Head', 'Shoulder', 'Torso', 'Leg', 'Hand', 'Feet', 'Amulet', 'Armring', 'Cloak', 'Neck', 'Ring'])}
      
      {renderTextField('Min Damage', 'minDamage', 'number')}
      {renderTextField('Max Damage', 'maxDamage', 'number')}
      {renderTextField('Damage Cap', 'dmgCap', 'number')}
      {renderTextField('Crit Chance', 'critChance', 'number')}
      {renderTextField('Crit Damage', 'critDamage', 'number')}
      {renderCheckbox('Can Equip in Off-Hand', 'canEquipInOffHand')}
      {renderSelectField('Weapon Type', 'weaponType', ['Axe', 'Sword', 'Mace', 'Dagger', 'Staff', 'Chain'])}
      {renderTextField('Actions per Round', 'actionsPerRound', 'number')}
      {renderTextField('Momentum Chance', 'momentumChance', 'number')}
      {renderTextField('Momentum Multiplier', 'momentumMultiplier', 'number')}
      {renderTextField('Strength Requirement', 'strengthRequirement', 'number')}
  
      {renderTextField('Absorption', 'absorption', 'number')}
      {renderTextField('Absorption Percentage', 'absorptionPercentage', 'number')}
      {renderTextField('Min Absorption', 'minAbsorption', 'number')}
      {renderTextField('Max Absorption', 'maxAbsorption', 'number')}
      {renderSelectField('Armor Type', 'armorType', ['Head', 'Shoulder', 'Torso', 'Leg', 'Hand', 'Feet'])}
  
      {renderTextField('Block Value', 'blockValue', 'number')}
      {renderTextField('Break Value', 'breakValue', 'number')}
      {renderSelectField('Shield Type', 'shieldType', ['Small', 'Medium', 'Large'])}
  
      {renderSelectField('Accessory Type', 'accessoryType', ['Amulet', 'Armring', 'Cloak', 'Neck', 'Ring'])}
      {renderTextField('Bonuses', 'bonuses')}
      
      {renderTextField('Material Type', 'materialType')}
  
      {renderTextField('Potion Type', 'potionType')}
      {renderTextField('Potion Effect', 'potionEffect')}
      {renderTextField('Potion Duration', 'duration', 'number')}
  
      {renderTextField('Enchant Modifiers', 'enchantModifiers')}
      {renderTextField('Knowledge Type', 'knowledgeType')}
      {renderTextField('Effect Description', 'effectDescription')}
  
      {renderCheckbox('Public', 'public')}
    </>
  );

  useEffect(() => {
    if (searchTerm.length > 2) {
      handleSearch();
    }
  }, [searchTerm]);

  return (
    <div className={styles.editItemContainer}>
      <h1>Edit Item</h1>
      <div className={styles.searchSection}>
        <input
          type="text"
          placeholder="Search item by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className={styles.searchResults}>
        {searchResults.map((item) => (
          <div key={item.id} className={styles.searchResult} onClick={() => handleSelectItem(item.id)}>
            {item.name}
          </div>
        ))}
      </div>
      {selectedItem && (
        <div className={styles.formSection}>
          <h2>Editing: {selectedItem.name}</h2>
          <label>
            <input
              type="checkbox"
              checked={showAllFields}
              onChange={(e) => setShowAllFields(e.target.checked)}
            />
            Show All Fields
          </label>
          {showAllFields ? renderAllFields() : (
            <>
              {renderTextField('Name', 'name')}
              <div className={styles.formGroup}>
                <label>Description:</label>
                <textarea name="description" value={itemData.description ?? ''} onChange={handleInputChange} />
              </div>
              {renderConditionalFields()}
            </>
          )}
          <button className={styles.saveButton} onClick={handleSave}>Save Changes</button>
          {message && <p className={styles.message}>{message}</p>}
        </div>
      )}
    </div>
  );
};

export default EditItem;
