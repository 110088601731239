import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useGladiator } from '../../../contexts/GladiatorContext';
import './AdventureList.css';

interface Adventure {
  id: number;
  title: string;
  rarity: string;
  starting_chapter_id: number;
  current_chapter_id?: number;
}

const AdventureList: React.FC = () => {
  const [ongoingAdventure, setOngoingAdventure] = useState<Adventure | null>(null);
  const { gladiator } = useGladiator();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdventures = async () => {
      try {
        if (gladiator) {
          const ongoingResponse = await axios.get(`/api/adventures/ongoing/${gladiator.id}`);
          if (ongoingResponse.data) {
            setOngoingAdventure(ongoingResponse.data);
          }
        }
      } catch (error) {
        console.error('Error fetching adventures:', error);
      }
    };

    fetchAdventures();
  }, [gladiator]);

  useEffect(() => {
    // Redirect to ongoing adventure if it exists
    if (ongoingAdventure && ongoingAdventure.current_chapter_id) {
      navigate(`/adventure/${ongoingAdventure.id}/chapter/${ongoingAdventure.current_chapter_id}`);
    }
  }, [ongoingAdventure, navigate]);

  const handleRandomAdventure = async () => {
    try {
      const response = await axios.get('/api/adventures/random');
      const adventure = response.data;

      if (gladiator) {
        await axios.post('/api/adventures/start', {
          gladiator_id: gladiator.id,
          adventure_id: adventure.id
        });
        navigate(`/adventure/${adventure.id}/chapter/${adventure.starting_chapter_id}`);
      } else {
        console.error('No gladiator found. Please select a gladiator to start an adventure.');
      }
    } catch (error) {
      console.error('Error starting random adventure:', error);
    }
  };

  return (
    <div className="adventure-list">
      <h2>Take a Break from the Arena and Embark on an Adventure</h2>
      <p className="intro-text">
        The city of Njordheim beckons to those bold enough to step away from the blood-stained sands of the arena. 
        Beyond the clash of steel and cries of battle lies a world of ancient secrets, hidden relics, and timeless lore waiting to be unearthed. 
        Will you dare to traverse forgotten ruins, delve into mysterious forests, or confront the whispers of spirits long lost to legend? 
        Arm yourself with courage, for every adventure brings with it a tale worthy of song at Stonebolt's Tavern and a chance at unimaginable treasure. 
        Step forward, brave gladiator, and let the path unveil its mysteries.
      </p>

      {ongoingAdventure ? (
        <p className="info-text">
          You are currently on an adventure titled <strong>{ongoingAdventure.title}</strong>. Redirecting you now...
        </p>
      ) : (
        <button onClick={handleRandomAdventure} className="start-adventure-button">
          Embark on a New Adventure!
        </button>
      )}
    </div>
  );
};

export default AdventureList;
