import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useGladiator } from '../../../contexts/GladiatorContext';
import Toast from '../Toast';
import styles from './CreateGladiator.module.css';

interface PrimaryPoints {
  health: number;
  strength: number;
  endurance: number;
  initiative: number;
  dodge: number;
  axe: number;
  sword: number;
  mace: number;
  staff: number;
  shield: number;
  dagger: number;
  chain: number;
}

interface SecondaryPoints {
  learning: number;
  luck: number;
  discipline: number;
  leadership: number;
  provocation: number;
}

interface RaceModifiers {
  raceName: string;
  health: number;
  strength: number;
  endurance: number;
  learningCapacity: number;
  discipline: number;
  initiative: number;
  avoidance: number;
  luck: number;
  weaponAxes: number;
  weaponSwords: number;
  weaponMaces: number;
  weaponStaves: number;
  weaponDaggers: number;
  weaponChain: number;
  weaponShields: number;
  weight: number;
  minimumCritChance: number;
  maximumCritChance: number;
  offHandDamage: number;
}

const initialPrimaryPoints: PrimaryPoints = {
  health: 0,
  strength: 0,
  endurance: 0,
  initiative: 0,
  dodge: 0,
  axe: 0,
  sword: 0,
  mace: 0,
  staff: 0,
  shield: 0,
  dagger: 0,
  chain: 0,
};

const initialSecondaryPoints: SecondaryPoints = {
  learning: 0,
  luck: 0,
  discipline: 0,
  leadership: 0,
  provocation: 0,
};

const CreateGladiator: React.FC = () => {
  const { user } = useAuth();
  const { setGladiatorId } = useGladiator();
  const [name, setName] = useState<string>('');
  const [race, setRace] = useState<string>('Elf');
  const [sex, setSex] = useState<string>('Male');
  const [primaryPoints, setPrimaryPoints] = useState<PrimaryPoints>(initialPrimaryPoints);
  const [secondaryPoints, setSecondaryPoints] = useState<SecondaryPoints>(initialSecondaryPoints);
  const [primaryPointsLeft, setPrimaryPointsLeft] = useState<number>(100);
  const [secondaryPointsLeft, setSecondaryPointsLeft] = useState<number>(5);
  const [avatar, setAvatar] = useState<number | null>(null);
  const [isNameAvailable, setIsNameAvailable] = useState<boolean | null>(null);
  const [raceBonuses, setRaceBonuses] = useState<RaceModifiers[]>([]);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRaceBonuses = async () => {
      try {
        const response = await axios.get('/api/gladiator/race-bonuses');
        setRaceBonuses(response.data);
      } catch {
        displayToast('Error fetching race bonuses.', 'error');
      }
    };
    fetchRaceBonuses();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (name) {
        try {
          const response = await axios.get(`/api/gladiator/check-name?name=${name}`);
          setIsNameAvailable(response.data.isAvailable);
        } catch {
          displayToast('Error checking name availability.', 'error');
        }
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [name]);

  const handlePrimaryChange = (skill: keyof PrimaryPoints, value: string) => {
    const parsedValue = value === '' ? 0 : parseInt(value, 10);
    const newPoints = { ...primaryPoints, [skill]: parsedValue };
    const totalPoints = Object.values(newPoints).reduce((a, b) => a + b, 0);
    if (totalPoints <= 100) {
      setPrimaryPoints(newPoints);
      setPrimaryPointsLeft(100 - totalPoints);
    }
  };

  const handleSecondaryChange = (skill: keyof SecondaryPoints, value: string) => {
    const parsedValue = value === '' ? 0 : parseInt(value, 10);
    const newPoints = { ...secondaryPoints, [skill]: parsedValue };
    const totalPoints = Object.values(newPoints).reduce((a, b) => a + b, 0);
    if (totalPoints <= 5) {
      setSecondaryPoints(newPoints);
      setSecondaryPointsLeft(5 - totalPoints);
    }
  };

  const getAvatarGrid = () => {
    const avatars = [];
    for (let i = 1; i <= 9; i++) {
      avatars.push(
        <div
          key={i}
          className={`${styles.avatarItem} ${avatar === i ? styles.selected : ''}`}
          onClick={() => setAvatar(i)}
        >
          <img
            src={`/img/avatars/${race.toLowerCase()}.${sex.toLowerCase()}.${i}.png`}
            alt={`Avatar ${i}`}
          />
        </div>
      );
    }
    return avatars;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (primaryPointsLeft !== 0 || secondaryPointsLeft !== 0) {
      displayToast('You must allocate all points.', 'error');
      return;
    }
    if (avatar === null) {
      displayToast('You must select an avatar.', 'error');
      return;
    }
    if (isNameAvailable === false) {
      displayToast('The gladiator name is already taken.', 'error');
      return;
    }

    try {
      const response = await axios.post(
        '/api/gladiator',
        { name, race, sex, avatar, ...primaryPoints, ...secondaryPoints },
        { withCredentials: true }
      );
      setGladiatorId(response.data.gladiatorId);
      displayToast('Gladiator created successfully!', 'success');
      navigate('/gladiator'); // Do we need this?
    } catch {
      displayToast('Error creating gladiator.', 'error');
    }
  };

  const weaponMapping: { [key: string]: string } = {
    axe: 'weaponAxes',
    sword: 'weaponSwords',
    mace: 'weaponMaces',
    staff: 'weaponStaves',
    shield: 'weaponShields',
    dagger: 'weaponDaggers',
    chain: 'weaponChain',
  };

  const attributeMapping: { [key: string]: string } = {
    health: 'health',
    strength: 'strength',
    endurance: 'endurance',
    initiative: 'initiative',
    dodge: 'avoidance',
  };

  const secondarySkillMapping: { [key: string]: string } = {
    learning: 'learningCapacity',
    luck: 'luck',
    discipline: 'discipline',
    leadership: '',
    provocation: '',
  };

  const getSelectedRaceBonus = (skill: string) => {
    const selectedRace = raceBonuses.find((r) => r.raceName === race);
    const mappedKey = weaponMapping[skill] || attributeMapping[skill] || secondarySkillMapping[skill];
    if (selectedRace && mappedKey && mappedKey in selectedRace) {
      const bonusValue = selectedRace[mappedKey as keyof RaceModifiers] ?? 0;
      const percentage = (Number(bonusValue) - 1) * 100;
      return percentage >= 0 ? `+${percentage.toFixed(0)}%` : `${percentage.toFixed(0)}%`;
    }
    return '0%';
  };

  const displayToast = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
  };

  return (
    <div className={styles.createGladiatorContainer}>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          duration={3000}
          onClose={() => setToastMessage(null)}
        />
      )}
      <h2>Create Your Gladiator</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.createGladiatorRow}>
          <div>
            <label>Gladiator Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              required
            />
            {isNameAvailable === false && <p className={styles.errorText}>Name is already taken</p>}
          </div>
          <div>
            <label>Race:</label>
            <select value={race} onChange={(e: ChangeEvent<HTMLSelectElement>) => setRace(e.target.value)} required>
              {raceBonuses.map((raceOption) => (
                <option key={raceOption.raceName} value={raceOption.raceName}>
                  {raceOption.raceName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Sex:</label>
            <select value={sex} onChange={(e: ChangeEvent<HTMLSelectElement>) => setSex(e.target.value)} required>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>

        <div className={styles.avatarSection}>
          <h3>Select Your Avatar</h3>
          <div className={styles.avatarGrid}>{getAvatarGrid()}</div>
        </div>

        <div className={styles.primarySkillSection}>
          <div className={styles.attributesSection}>
            <h3>Attributes</h3>
            {['health', 'strength', 'endurance', 'initiative', 'dodge'].map((skill) => (
              <div key={skill} className={styles.createSkillInput}>
                <label>{skill.charAt(0).toUpperCase() + skill.slice(1)}:</label>
                <input
                  type="number"
                  value={primaryPoints[skill as keyof PrimaryPoints] === 0 ? '' : primaryPoints[skill as keyof PrimaryPoints]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handlePrimaryChange(skill as keyof PrimaryPoints, e.target.value)}
                  min="0"
                  max="100"
                />
                <span className={styles.modifier}>
                  {getSelectedRaceBonus(skill)}
                </span>
              </div>
            ))}
          </div>

          <div className={styles.weaponSkillsSection}>
            <h3>Weapon Skills</h3>
            {['axe', 'sword', 'mace', 'staff', 'shield', 'dagger', 'chain'].map((skill) => (
              <div key={skill} className={styles.createSkillInput}>
                <label>{skill.charAt(0).toUpperCase() + skill.slice(1)}:</label>
                <input
                  type="number"
                  value={primaryPoints[skill as keyof PrimaryPoints] === 0 ? '' : primaryPoints[skill as keyof PrimaryPoints]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handlePrimaryChange(skill as keyof PrimaryPoints, e.target.value)}
                  min="0"
                  max="100"
                />
                <span className={styles.modifier}>
                  {getSelectedRaceBonus(skill)}
                </span>
              </div>
            ))}
          </div>
        </div>
        <p>Primary Points left: {primaryPointsLeft}</p>

        <div className={styles.secondarySkillSection}>
          <h3>Secondary Skills (5 total)</h3>
          {['learning', 'luck', 'discipline', 'leadership', 'provocation'].map((skill) => (
            <div key={skill} className={styles.createSkillInput}>
              <label>{skill.charAt(0).toUpperCase() + skill.slice(1)}:</label>
              <input
                type="number"
                value={secondaryPoints[skill as keyof SecondaryPoints] === 0 ? '' : secondaryPoints[skill as keyof SecondaryPoints]}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSecondaryChange(skill as keyof SecondaryPoints, e.target.value)}
                min="0"
                max="5"
              />
              <span className={styles.modifier}>
                {getSelectedRaceBonus(skill)}
              </span>
            </div>
          ))}
        </div>

        <p>Secondary Points left: {secondaryPointsLeft}</p>

        <button type="submit" className={styles.submitButton}>Create Gladiator</button>
      </form>
    </div>
  );
};

export default CreateGladiator;
