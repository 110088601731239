import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGladiator } from '../../../contexts/GladiatorContext';
import styles from './MyGladiators.module.css';

interface Gladiator {
  id: number;
  name: string;
  level: number;
  race: string;
  sex: string;
  avatar: number;
}

const MyGladiators: React.FC = () => {
  const navigate = useNavigate();
  const { gladiators, fetchUserGladiators, switchGladiator, deleteGladiator } = useGladiator();
  const [canCreate, setCanCreate] = useState(false);
  const [creationMessage, setCreationMessage] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [gladiatorToDelete, setGladiatorToDelete] = useState<Gladiator | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!dataFetched) {
        const response = await fetchUserGladiators();
        if (response) {
          setCanCreate(response.canCreate);
          setCreationMessage(getCreationMessage(response.canCreate, response.gladiators, response.isPremiumOrHigher));
          setDataFetched(true);
        }
      }
    };
  
    fetchData();
  }, [fetchUserGladiators, dataFetched]);

  const getCreationMessage = (canCreate: boolean, gladiators: Gladiator[], isPremiumOrHigher: boolean) => {
    if (canCreate) return 'You can create a new gladiator!';
  
    if (gladiators.length === 1) {
      return gladiators[0].level < 15 && !isPremiumOrHigher
        ? 'Your first gladiator needs to be level 15 or higher to create a second one.'
        : '';
    }
  
    if (gladiators.length === 2) {
      const [firstGladiator, secondGladiator] = gladiators;
      const hasRequiredLevels = (firstGladiator.level >= 26 && secondGladiator.level >= 15) || (secondGladiator.level >= 26 && firstGladiator.level >= 15);
      const premiumRequirementMet = isPremiumOrHigher && (firstGladiator.level >= 15 || secondGladiator.level >= 15);
  
      return !hasRequiredLevels && !premiumRequirementMet
        ? 'To create a third gladiator, you need one gladiator at level 26 and another at level 15, or Premium status with one gladiator at level 15.'
        : '';
    }
  
    if (gladiators.length === 3 && !isPremiumOrHigher) {
      return 'Only Premium users can create a fourth gladiator.';
    }
  
    return 'You have reached the maximum number of gladiators allowed.';
  };

  const confirmDeleteGladiator = (gladiator: Gladiator) => {
    setGladiatorToDelete(gladiator);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = () => {
    if (gladiatorToDelete) {
      deleteGladiator(gladiatorToDelete.id);
      setShowDeleteConfirm(false);
      setGladiatorToDelete(null);
    }
  };

  return (
    <div className={styles.gladiatorsContainer}>
      <h2 className={styles.title}>My Gladiators</h2>
      {gladiators.length > 0 ? (
        <table className={styles.gladiatorTable}>
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Name</th>
              <th>Level</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {gladiators.map((gladiator: Gladiator) => (
              <tr key={gladiator.id}>
                <td>
                  <img
                    src={`/img/avatars/${gladiator.race?.toLowerCase() ?? 'default'}.${gladiator.sex?.toLowerCase() ?? 'default'}.${gladiator.avatar ?? 1}.png`}
                    alt={gladiator.name ?? 'Unknown Gladiator'}
                    className={styles.avatar}
                  />
                </td>
                <td>{gladiator.name}</td>
                <td>{gladiator.level}</td>
                <td>
                  <button className={styles.actionButton} onClick={() => switchGladiator(gladiator)}>
                    Select
                  </button>
                  <button className={styles.deleteButton} onClick={() => confirmDeleteGladiator(gladiator)}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={styles.noGladiatorsMessage}>No gladiators found. Create one to start your journey!</p>
      )}
      <div className={styles.creationSection}>
        <button
          className={styles.createButton}
          onClick={() => navigate('/create-gladiator')}
          disabled={!canCreate}
        >
          Create New Gladiator
        </button>
        {!canCreate && <p className={styles.creationMessage}>{creationMessage}</p>}
      </div>

      {/* Confirmation Modal */}
      {showDeleteConfirm && (
        <div className={styles.confirmOverlay}>
          <div className={styles.confirmModal}>
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete {gladiatorToDelete?.name}?</p>
            <div className={styles.modalButtons}>
              <button className={styles.confirmButton} onClick={handleDeleteConfirm}>Yes</button>
              <button className={styles.cancelButton} onClick={() => setShowDeleteConfirm(false)}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyGladiators;
