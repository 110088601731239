import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './contexts/AuthContext';
import { useGladiator } from './contexts/GladiatorContext';
import { useNavigate } from 'react-router-dom';

const AuthInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { setUser } = useAuth();
  const { setGladiator, gladiatorId, setGladiatorId } = useGladiator();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get('https://njordheim.com/api/auth/user', { withCredentials: true });
        if (userResponse.data) {
          setUser(userResponse.data);
          if (userResponse.data.isDead && userResponse.data.deathBattleReport) {
            navigate(`/deathscreen/${userResponse.data.deathBattleReport}`);
          }

          // Check for gladiator based on gladiatorId or fallback to first gladiator if gladiatorId is missing
          let gladiatorResponse;
          if (gladiatorId && gladiatorId !== undefined) {
            try {
              gladiatorResponse = await axios.get(`https://njordheim.com/api/gladiator/${gladiatorId}`, { withCredentials: true });
            } catch (error) {
              console.error('Failed to fetch gladiator by ID, attempting to fetch first gladiator:', error);
            }
          }

          if (!gladiatorResponse || gladiatorResponse.status === 404) {
            gladiatorResponse = await axios.get('https://njordheim.com/api/gladiator/first', { withCredentials: true });
            setGladiatorId(gladiatorResponse.data.id);
          }

          if (gladiatorResponse && gladiatorResponse.data) {
            setGladiator(gladiatorResponse.data);
          } else {
            navigate('/create-gladiator');
          }
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [setUser, setGladiator, setGladiatorId, gladiatorId, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};

export default AuthInitializer;
